(function() {
    'use strict';

    angular.module('EntrakV5').constant('LANG', {
"en": {
"unit": {
"degree": "°C",
"ppm": "ppm",
"ugm3": "μg/m³",
"hour": "Hours",
"minute": "Minutes",
"lx": "lx",
"kwh": "kWh"
},
"role": {
"DASHBOARD_ADMIN": "Dashboard admin",
"TENANT_USER": "User",
"ZONE_ADMIN": "Zone admin",
"TENANT_ADMIN": "Admin",
"LANDLORD_USER": "Landlord user",
"LANDLORD_ADMIN": "Landlord admin",
"SUPERUSER": "Super user",
"UNKNOWN": "Unknown"
},
"label": {
"disconnected": "Disconnected",
"minutes": "Minutes",
"adjustingTemperature": "Adjusting temperature...please wait",
"onUntil": "ON until ${time}",
"onWithoutTime": "Devices are ON",
"workstationName": "${fName} ${lName}'s workstation",
"fullName": "${fName} ${lName}",
"search": "Search",
"noDept": "No department",
"dashboard": "Dashboard",
"location": "Location",
"company": "Company",
"scene": "Scene",
"calendar": "Calendar",
"schedule": "Schedule",
"account": "Account",
"log": "Activity Log",
"heatmap": "Heatmap",
"dimLv": "Dim level",
"yesterday": "Yesterday",
"last7Days": "Last 7 days",
"last28Days": "Last 28 days",
"system": "System",
"publicHolidays": "Public holidays",
"selectAll": "Select all",
"to": "${from} to ${to}",
"temperature": "Temperature",
"humidity": "Relative humidity",
"co2": "Carbon dioxide",
"pm25": "PM2.5",
"hotDesk": "Hot desk",
"sent": "Sent!",
"copied": "copied!",
"alwaysOn": "Always on",
"high": "High",
"medium": "Medium",
"low": "Low",
"auto": "Auto",
"ADMIN": "Admin",
"ZONE_ADMIN": "Zone admin",
"SUPERUSER": "Super user",
"USER": "User",
"LANDLORD_USER": "Landlord user",
"UNKNOWN_ROLE": "Unknown",
"unknownUser": "Unknown user",
"pwdContain": "Password must contain:",
"pwdLowerCase": "at least 1 lower case letter",
"pwdUpperCase": "at least 1 upper case letter",
"pwdSpecialChar": "at least 1 special character",
"pwdNumber": "at least 1 number",
"pwdMin": "at least ${min} characters",
"pwdMinMax": "${min} to ${max} characters"
},
"nodeType": {
"company": "Company",
"floor": "Floor",
"room": "Common area",
"workstation": "Workstation"
},
"applicationType": {
"light": "Light",
"aircon": "Aircon",
"gateway": "Gateway",
"button": "Button",
"iaqSensor": "IAQ sensor",
"lightSensor": "Light sensor",
"motionSensor": "Occupancy sensor",
"fan": "Fan",
"thermometer": "Thermometer",
"energyMeter": "Energy meter",
"waterMeter": "Water meter",
"switch": "Wall switch",
"door": "Door",
"unknown": "Unknown"
},
"scheduleCtrl": {
"autoDesc": "Devices will turn ON automatically",
"manualDesc": "Require manual ON to activate session",
"isAltSat": "Alternative Saturdays",
"isLongWeek": "This week is a long week",
"autoLegend": "Auto ON",
"manualLegend": "Manual ON",
"priority": "Priority",
"custDayEndActionOff": "Devices will turn OFF automatically at the end of the day",
"custDayEndActionNone": "Devices status will not change at the end of the day"
},
"uploadCtrl": {
"upload": "Upload",
"invalidFormat": "Please select TXT/CSV file."
},
"button": {
"turnOn": "Turn ON",
"allOn": "Turn all ON",
"turnOff": "Turn OFF",
"allOff": "Turn all OFF",
"leaving": "To leave",
"here": "I'm here",
"moreAction": "I want...",
"keepOn": "I'm staying here",
"confirm": "Confirm",
"save": "Save",
"cancel": "Cancel",
"back": "Back",
"add": "Add",
"edit": "Edit",
"delete": "Delete",
"remove": "Remove",
"enable": "Enable",
"disable": "Disable",
"signIn": "Sign in",
"next": "Next",
"reset": "Reset",
"goSignIn": "Go back to sign in",
"done": "Done",
"createNewWs": "Create new workstation",
"createNewRm": "Create new common area",
"create": "Create",
"importAccounts": "Import accounts",
"import": "Import",
"manageDepartment": "Manage departments",
"manage": "Manage",
"addDepartment": "Add department",
"addAccount": "Add account",
"reinvite": "Reinvite",
"invite": "Invite",
"addInvite": "Add & Invite",
"inviteSelected": "Send invitation",
"deleteAccount": "Delete this account",
"createFloorChild": "Create groups",
"modeSettings": "Mode settings",
"apply": "Apply",
"copy": "Copy",
"selectWs": "Select workstation",
"selectLater": "Choose later",
"warmer": "Warmer",
"cooler": "Cooler",
"extendRoom": "Extend",
"editSchedule": "Edit schedule",
"createSchedule": "Create schedule",
"deleteSchedule": "Delete schedule",
"createScene": "Create scene",
"deleteScene": "Delete scene",
"removeFromScene": "Remove from scene",
"selectScene": "Select scene",
"generateApiKey": "Generate API key",
"deleteSpecialDay": "Delete special day",
"addSpecialDay": "Add special day",
"selectSpecialDay": "Select special days",
"editTimeslot": "Edit timeslots",
"deactivateScene": "Deactivate scenes",
"releaseWs": "Release workstation",
"delegate": "Delegate (${count})",
"switchHotdesk": "Switch to hot desk",
"switchWorkstation": "Switch workstation",
"selectOwners": "Select owners",
"editPolygon": "Edit location",
"deviceView": "Device view",
"boundaryView": "Boundary view"
},
"disconnectWin": {
"title": "Disconnected device",
"disconnectFound": "Found ${count} disconnected device(s)",
"actionMsg": "Please turn OFF and ON the wall switch to reconnect the devices. If that doesn't work, please contact your system administrator."
},
"delegateWsWin": {
"title": "Delegate workstation",
"ws": "Workstation",
"delegateTo": "Delegate to"
},
"error": {
"checkPwd": "Please check your password again.",
"generalResetPwdFail": "Unable to reset password.",
"generalActivateFail": "Unable to activate account.",
"invalidPwd": "Password should contain 8-16 characters, at least 1 number and 1 letter.",
"invalidPwdAdv": "Password should contain 10 or more characters, contain at least 2 types from lower case alphabets, upper case alphabets, numbers and special characters.",
"invalidLogin": "Sorry, your email and/or password is incorrect, please try again.",
"generalForgotFail": "Unable to send reset link.",
"generalLoginFail": "Unable to sign in.",
"generalNameFail": "Invalid name.",
"generalUpdateAccountFail": "Unable to update account.",
"accountExist": "An account with the same email already exists.",
"generalSendEmailFail": "Unable to send email.",
"invalidEmailFormat": "Invalid email format.",
"generalDelItemFail": "Unable to delete item.",
"generalDelWsFail": "Unable to delete workstation.",
"generalDelRmFail": "Unable to delete common area.",
"generalDelAccFail": "Unable to delete user account.",
"generalDelDeptFail": "Unable to delete department.",
"generalSaveFail": "Unable to save changes.",
"general": "Unable to proceed, please refresh your browser and try again.",
"generalControlFail": "Unable to control devices, please refresh your browser and try again.",
"noPortalAccess": "Sorry, your account doesn't have access right to visit this page. Please try again with another account.",
"ownerHasWorkstation": "The selected user is already assigned to another workstation.",
"invalidMsId": "Invalid Microsoft tenant ID.",
"invalidBookingEmail": "Email not found in booking system.",
"logMissingData": "Some devices have missing data, the calculation result may be inaccurate.",
"generalAddDelegateFail": "Unable to delegate workstation to user ${email}.",
"generalRemoveDelegateFail": "Unable to revoke delegation from user ${email}.",
"airconNotFound": "Unable to find the below aircon in this floor.",
"generalAssignOwnerFail": "Unable to assign owner.",
"generalAssignZoneAdminFail": "Unable to assign zone admin.",
"generalDelZoneAdminFail": "Unable to remove zone admin.",
"generalDelZoneFail": "Unable to remove zone.",
"generalDelOwnerFail": "Unable to remove owner.",
"missingDeviceInFloor": "Some device(s) are not found in this floor, you may not able to edit groups, please contact your system administrator.",
"alwaysOnNoSchedule": "The group belongs to an \"Always on\" floor, you cannot assign schedule to it."
},
"generalUpdatePolygonFail": "Unable to update location",
"header": {
"en": "English",
"zh": "繁體中文",
"cn": "简体中文",
"enShort": "Eng",
"zhShort": "繁",
"cnShort": "简",
"logout": "Sign out"
},
"lang": {
"EN_US": "English",
"ZH_HANT": "繁體中文",
"ZH_HANS": "简体中文",
"JA_JA": "日本語",
"TH_TH": "แบบไทย"
},
"profile": {
"title": "Profile",
"cardNo": "Access card number",
"account": "Account",
"password": "Password",
"email": "Email",
"fName": "First name",
"lName": "Last name",
"currentPwd": "Current password",
"newPwd": "New password",
"confirmPwd": "Confirm password",
"accessRight": "Access right",
"role": "Role",
"department": "Department",
"workstation": "Workstation",
"noWorkstation": "No workstation assigned",
"pwdExpired": "Password expired",
"pwdExpiredDesc": "Your password is expired, please change your password now.",
"notification": "Notifications",
"emailRequestNotifiation": "Email me when a user makes a large number of cooler/warmer requests",
"scheduleOffNotification": "Notify me 5 minutes before my workstation is scheduled to turn OFF",
"emailDeviceOfflineNotifiation": "Email me when one or more control devices has a problem"
},
"dashboard": {
"panel": {
"workstationOnUntil": "Your workstation is ON until ${time}",
"workstationOnWithoutTime": "Your workstation is ON",
"workstationOnAt": "Your workstation will turn ON at ${time}",
"welcome": "Welcome ${name}!",
"selectWsReminder": "You don't have a workstation, but you can choose one now!",
"switchWsReminder": "You already have a workstation, but you can also switch to another one.",
"workstationOnReminder": "Remember to turn OFF your workstation when you leave!",
"workstationOffReminder": "Let's turn ON your workstation.",
"workstationDisconnectMsg": "Some of your devices are disconnected. Please turn OFF and ON the wall switch to reconnect these devices."
},
"card": {
"status": "Status",
"devices": "Devices",
"viewAllWs": "View all workstations",
"viewAll": "View all",
"titleScene": "Scenes",
"titleWs": "Workstations",
"yourWs": "Your workstation",
"titleRoom": "Common areas",
"titleRecent": "Recent controls",
"titleVisitor": "Visitors",
"inviteVisitor": "Invite visitor",
"visitorInfo": "Visitor detail",
"noVisitorDesc": "If visitors come to your office, you can allow them to control workstation by adding them here.",
"email": "Email",
"startTime": "Start time",
"endTime": "End time",
"tabOn": "All ON groups",
"tabOff": "All OFF groups",
"downloadAppTitle": "Download app",
"downloadAppDesc": "Download the mobile app to control your office environment!",
"noWorkstationDesc": "You haven't been assigned a workstation",
"ios": "iOS",
"android": "Android",
"huawei": "Huawei",
"heatIndex": "Heat index: ${hi}",
"revokeVisitor": "Delete visitor",
"revokeDesc": "Are you sure you want to delete this visitor?",
"accessCode": "Access code"
},
"selectWsWin": {
"selectWsDesc": "Please select an available workstation below."
},
"timerWin": {
"onUntil": "Your workstation is ON until"
},
"schedulePopup": {
"title": "Schedule"
},
},
"company": {
"title": "Company",
"habitap": "Habitap integration",
"habitapId": "Habitap client ID",
"msTenantId": "Microsoft 365 tenant ID",
"apiKey": "API key",
"oauthProvider": "OAuth provider",
"msSubscription": "Microsoft calendar subscription",
"extension": "Extension",
"defaultExtend": "Default extension duration",
"maxExtend": "Max extension duration",
"heatIndexDefinition": "Heat index definition",
"defineHeatIndex": "Define heat index",
"heatIndex": "Heat index",
"temperature": "Temperature",
"humidity": "Relative humidity",
"allowedDomain": "Allowed domain",
"popup": {
"date": "Dates",
"addAllowedDomain": "Add allowed domain",
"addOAuthProvider": "Add OAuth provider",
"google": "Google",
"microsoft": "Microsoft"
},
"mircosoftId": "Microsoft tenant ID",
"googleId": "Google client ID",
"deleteDomainTitle": "Delete domain",
"deleteDomainDesc": "Are you sure you want to delete this domain?",
"deleteOAuthTitle": "Delete OAuth provider",
"deleteOAuthDesc": "Are you sure you want to delete this OAuth provider?",
"deleteSubscriptionTitle": "Delete subscription",
"deleteSubscriptionDesc": "Are you sure you want to unsubscribe this calendar?",
"advancedPwd": "Advanced password validation",
"disablePwd": "Sign in with username/password",
"msPermission": "Microsoft calendar integration",
"consentLink": "Consent link",
"allowSelfReg": "Allow self-registration",
"viewOthers": "View other workstaions",
"securitySection": "Identity and security",
"configurationSection": "Basic configurations",
"integrationSection": "Integration",
"enableVisitor": "Allow visitors",
"titleVisitor": "Enable visitor control",
"visitorDesc1": "This function allows your staff to invite visitors to turn on/off workstation lighting and/or air conditioning using a pre-printed QR code located at the desk in question. All users in your company will be able to use this function to invite visitors.",
"visitorDesc2": "To set up this workflow, you will need to first prepare QR codes for each desk a visitor may need to use (they will not have an account for the TEP app, so they need to access the system through a QR code scan). You can find the QR code of any workstation on the Location page, under the details for that workstation.",
"visitorDesc3": "Basic workflow:",
"visitorDesc4Head": "Invite a visitor",
"visitorDesc4": " - this function can be found on the Home page of the web app. Input the visitor's email and the date/time they will come to the office. They will be sent an invitation by email including a unique access code that is only valid during the specified period.",
"visitorDesc5Head": "Visitor arrives",
"visitorDesc5": " - they will use their mobile phone to scan the QR code on the workstation they choose to work at. Their phone will open a web page and ask them for the access code provided in the invitation email. When they input this code the lights and/or air conditioning will turn on/off.",
"defaultLanguage": "Default language"
},
"calendar": {
"title": "Calendar",
"specialDays": "Special days",
"showCity": "Show public holidays for",
"spDayName": "Special day name",
"deleteSpDayDesc": "Are you sure you want to delete this special day?",
"deleteSpDayTitle": "Delete speical day",
"selectDateDesc": "Click on the calendar to select dates."
},
"location": {
"title": "Locations",
"treeTitleFloorPlan": "Floor plan",
"treeTitleFloorChild": "Groups",
"treeTitleCorridor": "Corridors",
"titleDeleteWorkstation": "Delete this workstation",
"titleDeleteRoom": "Delete this common area",
"deleteFloorChildDesc": "Are you sure you want to delete this group?",
"locationName": "Name",
"defaultTemperature": "Default temperature",
"heatIndexIn": "Heat index (in schedule)",
"heatIndexOut": "Heat index (out schedule)",
"controlLogicIn": "Control logic (in schedule)",
"controlLogicOut": "Control logic (out schedule)",
"controlLogicCo2": "Control logic (CO₂ concentration)",
"co2concentration": "CO₂ concentration",
"logicGTE": "Greater than or equal to",
"logicLTE": "Lower than or equal to",
"logicEQ": "Equal to",
"logicNA": "No control logic",
"workingHrs": "Working hours",
"adminOnly": "Admin only",
"sharable": "Sharable",
"useCompSchedule": "Use company default schedule",
"createFloorChildDesc": "You haven’t created any groups yet. Click the button below to create common areas and workstations.",
"corridorDesc": "Select corridor settings for different periods.",
"corridorWorkingHrs": "During scheduled working hours",
"corridorNonWorkingHrs": "Outside scheduled working hours",
"mixOnOffDesc": "This option is the optimal mix of comfort and energy saving.",
"allOnDesc": "This option is least energy-saving, but keeps the entire office bright.",
"allOffDesc": "This option saves the most energy by switching off all the corridor lights.",
"mixOnOff": "Mixed On Off",
"allOn": "All On",
"allOff": "All Off",
"owner": "Owner",
"corridorDevices": "Corridor devices",
"titleCustLighingPlan": "Customize corridor lighting",
"custLighingPlanDesc": "Select which corridor devices to turn ON during working hours.",
"deviceWillOn": "${count} devices will be ON",
"deviceWillOff": "${count} devices will be Off",
"floorPlan": "Floor plan",
"uploadedFloorPlan": "Uploaded floor plan:",
"serialNo": "Serial:",
"editFloorChild": "Edit group",
"addDeviceToFloorChild": "Click devices on the floorplan to add/remove them to this group.",
"numOfChildInFloor": "${count} groups on this floor.",
"createChildDesc": "Select devices to create new group.",
"floorChildName": "Group name",
"defaultName": "Default name",
"titleEditFloor": "Edit floor",
"someoneWorkstation": "${name}'s workstation",
"createLbl1": "Do you want to create a",
"createLbl2": "or",
"outlook": "Outlook",
"habitap": "Habitap",
"habitapExternalId": "Facility",
"outlookExternalId": "Email",
"managedBy": "Managed by",
"bookingSystem": "Booking system (${name}):",
"temperatureDuration": "Temperature change duration",
"coolerDelta": "Cooler delta",
"warmerDelta": "Warmer delta",
"heatMode": "Swith HVAC to heat mode",
"deviceProperty": "Device property",
"qrCodeDesc": "You can scan the QR code below to checkin or checkout this group.",
"forUser": "For normal user",
"forVisitor": "For visitor",
"qrCode": "QR code",
"floorAvg": "Floor average",
"deleteOwnerTitle": "Remove owner",
"deleteOwnerDesc": "Are you sure you want to remove this owner?",
"zoneAdmin": "Zone admin",
"deleteZoneAdminTitle": "Remove zone admin",
"deleteZoneAdminDesc": "Are you sure you want to remove this zone admin?",
"deleteExternalId": "Remove booking system linkage",
"deleteExternalIdDesc": "Are you sure you want to remove the booking system linkage for this common area?",
"fanSpeed": "Fan speed",
"offDelay": "Off delay",
"coordinates": "Boundary coordinates"
},
"schedule": {
"title": "Schedule",
"editTitle": "Edit schedule assignments",
"dragDropCardDesc": "Drag and drop the control group cards to assign them to schedules.",
"dragTimeslotDesc": "Click and drag to create a timeslot.",
"titleCreateSch": "Create new schedule",
"titleEditSch": "Edit schedule",
"todayNoSchedule": "No schedule today",
"scheduleName": "Name",
"schedule": "Weekly schedule",
"location": "Location",
"specialDay": "Special day schedule",
"deleteScheduleDesc": "If you delete this schedule, all workstations and common areas will have no schedule until reassigned.",
"groupNum": "Groups: ${count}",
"noScheduleDesc": "You have not created any schedules yet. Click the button below to create your first schedule.",
"selectSpDayDesc": "Select the special days to apply to this schedule.",
"allFloor": "All floors",
"scope": "Scope"
},
"log": {
"title": "Activity log",
"onDuration": "Total ON duration:",
"onMinutes": "${minutes} minutes",
"timeRange": "Time:",
"time": "Time",
"action": "Action",
"actionBy": "By",
"remark": "Remark",
"on": "On",
"off": "Off",
"coolerRequest": "Cooler request:",
"warmerRequest": "Warmer request:",
"noRequest": "No request",
"airconRequestTitle": "Aircon request",
"usageTitle": "Usage log",
"allGroups": "All groups",
"allFloors": "All floors",
"rooms": "Common areas",
"workstations": "Workstations",
"actionDesc": {
"COOLER": "Cooler",
"WARMER": "Warmer",
"EXTEND": "Extend",
"CHECKIN": "Checkin",
"CHECKOUT": "Checkout",
"NORMAL": "Return to default",
"REINSTATE": "Reinstate",
"UNKNOWN": "Unknown",
"ZONEON": "Zone on",
"ZONEOFF": "Zone off",
"DIMMING": "Dimming"
},
"downloadCsv": "Download CSV (${from} to ${to})",
"containUnknown": "${name} contains unknown action, the on/off duration for this group may not be accurate.",
"triggerType": {
"CARD": "Access control",
"TIMETABLE": "Schedule",
"USER": "User",
"API": "API",
"SCENE": "Scene",
"BUTTON": "Button",
"INVALID": "Invalid",
"RESERVATION": "Reservation",
"CONDITION": "Condition",
"MULTIGANG": "Wall switch",
"VISITOR": "Visitor",
"SYSTEM": "System",
"OCCUPANCY_SENSOR": "Occupancy sensor",
"LIGHT_SENSOR": "Light sensor",
"BOOKING_SYSTEM": "Booking system"
},
},
"account": {
"title": "Accounts",
"department": "Department",
"name": "Name",
"email": "Email",
"role": "Role",
"cardNo": "Access card",
"noWorkstation": "This staff member has not been assigned to a workstation.",
"addZone": "Assign zone",
"addAccountWin": {
"titleAddAcc": "Add account",
"titleEditAcc": "Edit account",
"cardNumber": "Access card number (optional)",
"fName": "First name",
"lName": "Last name"
},
"deleteUserTitle": "Delete account",
"deleteUserDesc": "Are you sure you want to delete this account?",
"deleteDeptTitle": "Delete department",
"deleteDeptDesc": "Are you sure you want to delete this department?",
"downloadTmpl": "You can download the template",
"here": "HERE",
"inviteAllWin": {
"title": "Invite accounts",
"desc": "You selected ${count} accounts. Are you sure you want to send invitation emails to these people?"
},
"deleteZoneTitle": "Remove zone",
"deleteZoneDesc": "Are you sure you want to remove this zone?"
},
"scene": {
"title": "Scene",
"noSceneDesc": "You have not created any scenes yet. Click the button below to create your first scene.",
"win": {
"titleCreate": "Create scene",
"titleEdit": "Edit scene",
"name": "Name",
"floor": "Floor"
},
"addDeviceToScene": "Click devices to add them to the scene.",
"numOfDeviceInScene": "${count} devices in this scene.",
"ctrlLogic": "Light sensor control logic",
"illuminance": "Illuminance"
},
"heatmap": {
"title": "Heatmap",
"coolerRequest": "Cooler request:",
"warmerRequest": "Warmer request:",
"noRecord": "No record found.",
"action": {
"cooler": "Cooler",
"warmer": "Warmer"
},
"actionBy": "Requester:",
"corridor": "Corridor",
"groupCovered": "Covered groups:",
"record": "Records:",
"noAirconDesc": "This floor does not contain any temperature-adjustable air conditioning units. Please refer to the Activity Log for on/off activity.",
"requestCount": "Request count",
"defaultTemperature": "Default temperature:",
"temperatureRange": "Temperature range:",
"averageTemperature": "Average temperature:",
"chartTitle": "Temperature records",
"setPointChange": "Adjust default temperature"
},
"mainPage": {
"title": "Control"
},
"locationPage": {
"title": "Location"
},
"scenePage": {
"title": "Scenes"
},
"companyPage": {
"title": "Settings"
},
"accountPage": {
"title": "Account"
},
"heatmapPage": {
"title": "Heatmap"
},
"logPage": {
"title": "Activity log"
},
"dashboardPage": {
"title": "Dashboard"
},
"insightsPage": {
"title": "Insights"
},
"schedulePage": {
"title": "Schedule"
},
"calendarPage": {
"title": "Calendar"
},
},
"zh": {
"unit": {
"degree": "°C",
"ppm": "ppm",
"ugm3": "μg/m³",
"hour": "小時",
"minute": "分鐘",
"lx": "lx",
"kwh": "kWh"
},
"role": {
"DASHBOARD_ADMIN": "儀表板管理員",
"TENANT_USER": "用戶",
"ZONE_ADMIN": "樓層管理員",
"TENANT_ADMIN": "管理員",
"LANDLORD_USER": "房東用戶",
"LANDLORD_ADMIN": "房東管理員",
"SUPERUSER": "超級系統管理員",
"UNKNOWN": "不明"
},
"label": {
"disconnected": "離線",
"minutes": "分分鐘",
"adjustingTemperature": "溫度調整中...請稍候",
"onUntil": "開啟至${time}",
"onWithoutTime": "設備已經開啟",
"workstationName": "${fName}${lName}的位置",
"fullName": "${fName}${lName}",
"search": "搜尋",
"noDept": "沒有部門",
"dashboard": "儀錶板",
"location": "地點",
"company": "公司",
"scene": "場景",
"calendar": "日曆",
"schedule": "行程",
"account": "帳戶",
"log": "活動記錄",
"heatmap": "熱圖",
"dimLv": "亮度",
"yesterday": "昨天",
"last7Days": "過去 7 天",
"last28Days": "過去 28 天",
"system": "系統",
"publicHolidays": "公眾假期",
"selectAll": "全選",
"to": "${from}至${to}",
"temperature": "溫度",
"humidity": "相對濕度",
"co2": "二氧化碳",
"pm25": "PM2.5",
"hotDesk": "共用位置",
"sent": "發送成功！",
"copied": "複製成功！",
"alwaysOn": "設備常開",
"high": "高",
"medium": "中",
"low": "低",
"auto": "自動",
"ADMIN": "系統管理員",
"ZONE_ADMIN": "樓層管理員",
"SUPERUSER": "超級系統管理員",
"USER": "使用者",
"LANDLORD_USER": "地主用戶",
"UNKNOWN_ROLE": "不明",
"unknownUser": "不明用戶",
"pwdContain": "密碼必須包含：",
"pwdLowerCase": "至少一個小寫字母",
"pwdUpperCase": "至少一個大寫字母",
"pwdSpecialChar": "至少一個特殊字符",
"pwdNumber": "至少一個數字",
"pwdMin": "至少${min}個字符",
"pwdMinMax": "${min}至${max}個字符"
},
"nodeType": {
"company": "公司",
"floor": "樓層",
"room": "公共區域",
"workstation": "位置"
},
"applicationType": {
"light": "燈",
"aircon": "空調",
"gateway": "閘道",
"button": "按鈕",
"iaqSensor": "室內空氣質素傳感器",
"lightSensor": "光感應器",
"motionSensor": "感應傳感器",
"fan": "風扇",
"thermometer": "溫度計",
"energyMeter": "電錶",
"waterMeter": "水錶",
"switch": "牆壁開關",
"door": "門",
"unknown": "不明"
},
"scheduleCtrl": {
"autoDesc": "設備將自動開啟",
"manualDesc": "需要手動開啟設備",
"isAltSat": "長短週",
"isLongWeek": "本週是長週",
"autoLegend": "自動開啟",
"manualLegend": "手動開啟",
"priority": "優先次序",
"custDayEndActionOff": "設備將在當天結束時自動關閉",
"custDayEndActionNone": "設備的狀態將不會在當天結束時改變"
},
"uploadCtrl": {
"upload": "上載",
"invalidFormat": "請選擇 TXT/CSV 檔案。"
},
"button": {
"turnOn": "開啟",
"allOn": "全部開啟",
"turnOff": "關閉",
"allOff": "全部關閉",
"leaving": "離開",
"here": "我在這裡",
"moreAction": "我想...",
"keepOn": "我會多留一會",
"confirm": "確認",
"save": "保存",
"cancel": "取消",
"back": "返回",
"add": "新增",
"edit": "編輯",
"delete": "刪除",
"remove": "消除",
"enable": "生效",
"disable": "停止",
"signIn": "登入",
"next": "下一步",
"reset": "重設",
"goSignIn": "回到登入頁面",
"done": "完畢",
"createNewWs": "創建新的位置",
"createNewRm": "建立新的公共區域",
"create": "創建",
"importAccounts": "上載帳戶",
"import": "上載",
"manageDepartment": "編輯部門",
"manage": "編輯",
"addDepartment": "新增部門",
"addAccount": "新增帳戶",
"reinvite": "重新邀請",
"invite": "邀請",
"addInvite": "新增及邀請",
"inviteSelected": "發送邀請",
"deleteAccount": "刪除該帳戶",
"createFloorChild": "創建群組",
"modeSettings": "模式設定",
"apply": "套用",
"copy": "複製",
"selectWs": "選擇位置",
"selectLater": "待會再選擇",
"warmer": "暖一點",
"cooler": "涼一點",
"extendRoom": "延長",
"editSchedule": "編輯日程",
"createSchedule": "創建時間表",
"deleteSchedule": "刪除行程",
"createScene": "創建場景",
"deleteScene": "刪除場景",
"removeFromScene": "從場景中移除",
"selectScene": "選擇場景",
"generateApiKey": "產生API金鑰",
"deleteSpecialDay": "刪除特別日子",
"addSpecialDay": "新增特別日子",
"selectSpecialDay": "選擇特別日子",
"editTimeslot": "編輯時段",
"deactivateScene": "停用場景",
"releaseWs": "釋放位置",
"delegate": "委托 (${count})",
"switchHotdesk": "轉到共用位置",
"switchWorkstation": "轉到其他位置",
"selectOwners": "分配用戶",
"editPolygon": "編輯位置",
"deviceView": "設備視圖",
"boundaryView": "邊界視圖"
},
"disconnectWin": {
"title": "離線的設備",
"disconnectFound": "找到${count}離線的設備",
"actionMsg": "請先關閉然後再開啟牆上的電源使設備重新連接。如果還是無效，請聯繫你的系統管理員。"
},
"delegateWsWin": {
"title": "委托位置",
"ws": "位置",
"delegateTo": "委托給"
},
"error": {
"checkPwd": "請再次檢查您的密碼。",
"generalResetPwdFail": "無法重設密碼。",
"generalActivateFail": "無法啟動帳戶。",
"invalidPwd": "密碼應包含8-16個字符，最少1個數字和1個字母。",
"invalidPwdAdv": "密碼應包含最少10個字符，最少包括數字，大楷字母，小楷字母和特別符號中的其中2組。",
"invalidLogin": "對不起，你的電郵地址和/或密碼不正確，請重試。",
"generalForgotFail": "無法發送重設密碼的電郵。",
"generalLoginFail": "無法登入。",
"generalNameFail": "名稱無效。",
"generalUpdateAccountFail": "無法更改帳戶資料。",
"accountExist": "相同的電郵地址已被使用。",
"generalSendEmailFail": "無法發送電郵。",
"invalidEmailFormat": "無效的電郵地址。",
"generalDelItemFail": "無法刪除項目。",
"generalDelWsFail": "無法刪除位置。",
"generalDelRmFail": "無法刪除公共區域。",
"generalDelAccFail": "無法刪除帳戶。",
"generalDelDeptFail": "無法刪除部門。",
"generalSaveFail": "無法儲存變更。",
"general": "無法處理，請刷新瀏覽器，然後再試一次。",
"generalControlFail": "無法控制設備，請刷新瀏覽器，然後再試一次。",
"noPortalAccess": "對不起，您的帳戶沒有訪問此頁面的訪問權限。請使用其他帳戶重試。",
"ownerHasWorkstation": "此用戶已被分配到另一個位置。",
"invalidMsId": "無效的微軟(Microsoft)365租使用者識別碼。",
"invalidBookingEmail": "預訂房間系統沒有此電郵地址。",
"logMissingData": "一些設備的數據不齊全，計算結果可能不准確。",
"generalAddDelegateFail": "無法新增委托至${email}。",
"generalRemoveDelegateFail": "無法取消對${email}的委托。",
"airconNotFound": "無法在這樓層找到此空調。",
"generalAssignOwnerFail": "無法分配用戶到此位置。",
"generalAssignZoneAdminFail": "無法分配樓層管理員到此樓層。",
"generalDelZoneAdminFail": "無法刪除樓層管理員分配。",
"generalDelZoneFail": "無法刪除樓層。",
"generalDelOwnerFail": "無法刪除用戶。",
"missingDeviceInFloor": "找不到此樓層的一些設備，你可能無法更改群組，請聯繫你的系統管理員。",
"alwaysOnNoSchedule": "此群組所在的樓層已設為“設備常開”，你無法為其分配時間表。"
},
"generalUpdatePolygonFail": "無法更新位置。",
"header": {
"en": "English",
"zh": "繁體中文",
"cn": "简体中文",
"enShort": "Eng",
"zhShort": "繁",
"cnShort": "简",
"logout": "登出"
},
"lang": {
"EN_US": "English",
"ZH_HANT": "繁體中文",
"ZH_HANS": "简体中文",
"JA_JA": "日本語",
"TH_TH": "แบบไทย"
},
"profile": {
"title": "個人檔案",
"cardNo": "門禁卡號碼",
"account": "帳戶",
"password": "密碼",
"email": "電郵",
"fName": "名",
"lName": "姓氏",
"currentPwd": "目前密碼",
"newPwd": "新密碼",
"confirmPwd": "確認密碼",
"accessRight": "權限",
"role": "職能",
"department": "部門",
"workstation": "位置",
"noWorkstation": "沒有指定位置",
"pwdExpired": "密碼已過期",
"pwdExpiredDesc": "你的密碼已過期，請立即更改密碼。",
"notification": "通知",
"emailRequestNotifiation": "當用戶有大量較涼快/較溫暖的請求時，給我發電子郵件",
"scheduleOffNotification": "在我的位置預定關燈時間前5分鐘通知我",
"emailDeviceOfflineNotifiation": "當有一個或多個設備出現問題時，給我發電子郵件"
},
"dashboard": {
"panel": {
"workstationOnUntil": "你的位置會開啟至${time}",
"workstationOnWithoutTime": "你的位置已經開啟",
"workstationOnAt": "你的位置將在${time}開啟",
"welcome": "歡迎${name}！",
"selectWsReminder": "你沒有位置，但你可以現在選擇一個！",
"switchWsReminder": "你已經有位置，但你還可以轉換到其他位置。",
"workstationOnReminder": "當你離開時請記得關閉你的設備！",
"workstationOffReminder": "開啟你的設備。",
"workstationDisconnectMsg": "你的部分設備離線。請先關閉再開啟電源重使設備新連接。"
},
"card": {
"status": "地位",
"devices": "裝置",
"viewAllWs": "查看所有位置",
"viewAll": "看全部",
"titleScene": "場景",
"titleWs": "位置",
"yourWs": "你的位置",
"titleRoom": "公共區域",
"titleRecent": "最近的控制",
"titleVisitor": "訪客",
"inviteVisitor": "邀請訪客",
"visitorInfo": "訪客資料",
"noVisitorDesc": "如果有訪客來到你的辦公室，你可以在此添加他們讓他們可以控制位置的開關。",
"email": "電郵地址",
"startTime": "開始時間",
"endTime": "結束時間",
"tabOn": "所有開啟的群組",
"tabOff": "所有關閉的群組",
"downloadAppTitle": "下載應用程式",
"downloadAppDesc": "下載流動應用程序來控制你的辦公室環境！",
"noWorkstationDesc": "你還沒有被分配到一個位置",
"ios": "iOS",
"android": "Android",
"huawei": "Huawei",
"heatIndex": "溫度指數: ${hi}",
"revokeVisitor": "刪除訪客",
"revokeDesc": "你確定要刪除這個訪客嗎？",
"accessCode": "驗証碼"
},
"selectWsWin": {
"selectWsDesc": "請在下面選擇一個可用的位置。"
},
"timerWin": {
"onUntil": "你的位置會開啟至"
},
"schedulePopup": {
"title": "行程"
},
},
"company": {
"title": "公司",
"habitap": "Habitap 整合",
"habitapId": "Habitap 用戶識別碼",
"msTenantId": "微軟(Microsoft)365租使用者識別碼",
"apiKey": "API金鑰",
"oauthProvider": "開放授權(OAuth)提供者",
"msSubscription": "微軟(Microsoft)日曆訂閱",
"extension": "延長時間",
"defaultExtend": "預設延長時間",
"maxExtend": "最大延長時間",
"heatIndexDefinition": "溫度指數定義",
"defineHeatIndex": "定義溫度指數",
"heatIndex": "溫度指數",
"temperature": "溫度",
"humidity": "相對濕度",
"allowedDomain": "允許的網域",
"popup": {
"date": "日期",
"addAllowedDomain": "新增允許網域",
"addOAuthProvider": "新增開放授權(OAuth)提供者",
"google": "谷歌(Google)",
"microsoft": "微軟(Microsoft)"
},
"mircosoftId": "微軟(Microsoft)365租使用者識別碼",
"googleId": "谷歌客戶端識別碼",
"deleteDomainTitle": "刪除網域",
"deleteDomainDesc": "你確定要刪除這個網域？",
"deleteOAuthTitle": "刪除開放授權(OAuth)提供者",
"deleteOAuthDesc": "你確定要刪除這個開放授權(OAuth)提供者？",
"deleteSubscriptionTitle": "刪除訂閱",
"deleteSubscriptionDesc": "你確定要刪除這個訂閱？",
"advancedPwd": "進階密碼驗證",
"disablePwd": "用賬戶名稱/密碼登入",
"msPermission": "受權使用微軟(Microsoft)日曆",
"consentLink": "受權鏈結",
"allowSelfReg": "容許用戶注冊",
"viewOthers": "容許查看他人位置",
"securitySection": "身份和安全性",
"configurationSection": "基本設定",
"integrationSection": "整合",
"enableVisitor": "容許訪客使用",
"titleVisitor": "啟用訪客控制",
"visitorDesc1": "此功能允許你的員工邀請訪客，使用位於位置上預先印刷的QR碼開/關位置的燈和/或空調。你公司中的所有用戶將能夠使用此功能來邀請訪客。",
"visitorDesc2": "要設置此工作流程，你將需要為每個訪客可能需要使用的位置準備QR碼（它們將沒有TEP的帳戶，因此他們需要通過QR碼掃描訪問系統）。你可以在地點頁面看到所有位置，在位置的詳細信息下找到該位置的QR碼。",
"visitorDesc3": "基本工作流程：",
"visitorDesc4Head": "邀請訪客",
"visitorDesc4": " - 此功能可以在TEP網頁版的儀錶板上找到。輸入訪客的電郵地址和他們將到辦公室的日期/時間。他們將收到邀請電郵，電郵包括唯一的訪問代碼，該代碼僅在指定的時間段內有效。",
"visitorDesc5Head": "訪客到達",
"visitorDesc5": " - 訪客將使用他們的手機掃描他們選擇的位置上的QR碼。他們的手機將打開一個網頁並詢問邀請電郵中提供的訪問代碼。當輸入此代碼時，燈和/或空調將打開/關閉。",
"defaultLanguage": "預設語言"
},
"calendar": {
"title": "日曆",
"specialDays": "特別日子",
"showCity": "顯示地區公眾假期",
"spDayName": "特別日子名稱",
"deleteSpDayDesc": "你確定要刪除這個特別日子？",
"deleteSpDayTitle": "刪除特別日子",
"selectDateDesc": "點擊日歷來選擇日期。"
},
"location": {
"title": "地點",
"treeTitleFloorPlan": "平面圖",
"treeTitleFloorChild": "群組",
"treeTitleCorridor": "走廊",
"titleDeleteWorkstation": "刪除這個位置",
"titleDeleteRoom": "刪除該公共區域",
"deleteFloorChildDesc": "你確定要刪除該群組？",
"locationName": "姓名",
"defaultTemperature": "預設溫度",
"heatIndexIn": "溫度指數(時間表內)",
"heatIndexOut": "溫度指數(時間表外)",
"controlLogicIn": "控制邏輯 (時間表外)",
"controlLogicOut": "控制邏輯 (時間表內)",
"controlLogicCo2": "控制邏輯 (二氧化碳濃度)",
"co2concentration": "二氧化碳濃度",
"logicGTE": "大於或等於",
"logicLTE": "小於或等於",
"logicEQ": "等於",
"logicNA": "無控制邏輯",
"workingHrs": "工作時間",
"adminOnly": "只限管​​理員",
"sharable": "容許多個用戶",
"useCompSchedule": "使用公司預設時間表",
"createFloorChildDesc": "你尚未創建任何群組。點擊下面的按鈕來創建公共區域和位置。",
"corridorDesc": "選擇不同時段的走廊設置。",
"corridorWorkingHrs": "工作時間內",
"corridorNonWorkingHrs": "工作時間外",
"mixOnOffDesc": "此選項是舒適性和節能的最佳組合。",
"allOnDesc": "此選項是最節能的，但保持整個辦公室有足夠亮度。",
"allOffDesc": "通過關閉所有走廊燈，此選項節約了大部分能量。",
"mixOnOff": "混合開關",
"allOn": "全部開啟",
"allOff": "全部關閉",
"owner": "用戶",
"corridorDevices": "走廊設備",
"titleCustLighingPlan": "自定走廊燈",
"custLighingPlanDesc": "選擇工作時間內想開啟的走廊設備。",
"deviceWillOn": "${count}個設備將會開啟",
"deviceWillOff": "${count}個設備將會關閉",
"floorPlan": "平面圖",
"uploadedFloorPlan": "上載平面圖：",
"serialNo": "序號:",
"editFloorChild": "編輯群組",
"addDeviceToFloorChild": "點擊平面圖上的設備來添加/刪除至此群組。",
"numOfChildInFloor": "${count}此樓層的群組。",
"createChildDesc": "選擇設備來創建新群組。",
"floorChildName": "群組名稱",
"defaultName": "預設名稱",
"titleEditFloor": "編輯樓層",
"someoneWorkstation": "${name}的位置",
"createLbl1": "你想創建一個",
"createLbl2": "或",
"outlook": "Outlook",
"habitap": "Habitap",
"habitapExternalId": "Facility",
"outlookExternalId": "電郵",
"managedBy": "管理者",
"bookingSystem": "訂房系統 (${name}):",
"temperatureDuration": "溫度變化時間",
"coolerDelta": "溫度下降值",
"warmerDelta": "溫度上升值",
"heatMode": "轉換HVAC到熱模式",
"deviceProperty": "設備屬性",
"qrCodeDesc": "你可以掃描下面的二維碼來開啟或關閉此群組。",
"forUser": "供用戶使用",
"forVisitor": "供訪客使用",
"qrCode": "二維碼",
"floorAvg": "樓層平均值",
"deleteOwnerTitle": "刪除用戶分配",
"deleteOwnerDesc": "你確定要刪除這位項用戶分配嗎？",
"zoneAdmin": "樓層管理員",
"deleteZoneAdminTitle": "刪除樓層管理員分配",
"deleteZoneAdminDesc": "你確定要刪除這位項樓層管理員分配嗎？",
"deleteExternalId": "刪除預訂系統鏈結",
"deleteExternalIdDesc": "你確定要刪除這公共區域與預訂系統的鏈結嗎?",
"fanSpeed": "風速",
"offDelay": "延遲關閉",
"coordinates": "邊界座標"
},
"schedule": {
"title": "時間表",
"editTitle": "分配時間表",
"dragDropCardDesc": "拖放群組卡到時間表。",
"dragTimeslotDesc": "點擊並拖動滑鼠以創建一個時段。",
"titleCreateSch": "建立新的時間表",
"titleEditSch": "編輯日程",
"todayNoSchedule": "今天沒有時間表",
"scheduleName": "姓名",
"schedule": "每週時間表",
"location": "地點",
"specialDay": "特別日子時間表",
"deleteScheduleDesc": "如果刪除這個時間表，在重新分配時間表前所有位置和公共區域將沒有時間表。",
"groupNum": "群組：${count}",
"noScheduleDesc": "你還沒有創建任何時間表。點擊下面的按鈕來創建你的第一個時間表。",
"selectSpDayDesc": "選擇會影響此時間表的特別日子",
"allFloor": "所有樓層",
"scope": "範圍"
},
"log": {
"title": "活動記錄",
"onDuration": "總開啟時間：",
"onMinutes": "${minutes}分鐘",
"timeRange": "時間：",
"time": "時間",
"action": "行動",
"actionBy": "行動者",
"remark": "備注",
"on": "開",
"off": "關",
"coolerRequest": "要求涼一點：",
"warmerRequest": "要求暖一點：",
"noRequest": "無要求",
"airconRequestTitle": "溫度要求",
"usageTitle": "使用記錄",
"allGroups": "所有群組",
"allFloors": "所有樓層",
"rooms": "公共區域",
"workstations": "位置",
"actionDesc": {
"COOLER": "涼些",
"WARMER": "暖些",
"EXTEND": "延長",
"CHECKIN": "開啟",
"CHECKOUT": "關閉",
"NORMAL": "回復正常溫度",
"REINSTATE": "恢復",
"UNKNOWN": "不明",
"ZONEON": "樓層全開",
"ZONEOFF": "樓層全關",
"DIMMING": "調節亮度"
},
"downloadCsv": "下載CSV (${from}至${to})",
"containUnknown": "${name}有不明行動記錄，此群組的開關時間計算可能不準確。",
"triggerType": {
"CARD": "門禁卡",
"TIMETABLE": "行程",
"USER": "使用者",
"API": "API",
"SCENE": "場景",
"BUTTON": "按鈕",
"INVALID": "無效",
"RESERVATION": "預訂",
"CONDITION": "狀態",
"MULTIGANG": "牆上開關",
"VISITOR": "訪客",
"SYSTEM": "系統",
"OCCUPANCY_SENSOR": "感應傳感器",
"LIGHT_SENSOR": "光感應器",
"BOOKING_SYSTEM": "預訂房間系統"
},
},
"account": {
"title": "帳戶",
"department": "部門",
"name": "姓名",
"email": "電郵",
"role": "職能",
"cardNo": "門禁卡",
"noWorkstation": "這名員工還沒有被分配到一個位置。",
"addZone": "分配樓層",
"addAccountWin": {
"titleAddAcc": "新增帳戶",
"titleEditAcc": "編輯帳戶",
"cardNumber": "門禁卡號碼",
"fName": "名",
"lName": "姓氏"
},
"deleteUserTitle": "刪除帳戶",
"deleteUserDesc": "您確定要刪除該帳戶嗎？",
"deleteDeptTitle": "刪除部門",
"deleteDeptDesc": "您確定要刪除該部門嗎？",
"downloadTmpl": "你可以下載這裡的",
"here": "模板文件",
"inviteAllWin": {
"title": "邀請帳戶",
"desc": "你選擇了${count}個賬戶。你確定要發送邀請電郵給他們？"
},
"deleteZoneTitle": "刪除樓層",
"deleteZoneDesc": "你確定要刪除這樓層嗎？"
},
"scene": {
"title": "場景",
"noSceneDesc": "你還沒有創建任何場景。點擊下面的按鈕來創建你的第一個場景。",
"win": {
"titleCreate": "創建場景",
"titleEdit": "編輯場景",
"name": "姓名",
"floor": "樓層"
},
"addDeviceToScene": "點擊設備將其添加到場景。",
"numOfDeviceInScene": "此場景有${count}個設備。",
"ctrlLogic": "光傳感器控制邏輯",
"illuminance": "照度"
},
"heatmap": {
"title": "熱圖",
"coolerRequest": "要求涼一點：",
"warmerRequest": "要求暖一點：",
"noRecord": "沒有找到記錄。",
"action": {
"cooler": "涼些",
"warmer": "暖些"
},
"actionBy": "請求者:",
"corridor": "走廊",
"groupCovered": "覆蓋群組：",
"record": "記錄：",
"noAirconDesc": "這樓層沒有任何可調節溫度的空調單元﹐請於活動記錄查看開關記錄。",
"requestCount": "要求次數",
"defaultTemperature": "預設溫度:",
"temperatureRange": "溫度變化:",
"averageTemperature": "平均溫度:",
"chartTitle": "溫度記錄",
"setPointChange": "調整預設溫度"
},
"mainPage": {
"title": "控制"
},
"locationPage": {
"title": "位置/設備"
},
"scenePage": {
"title": "場景"
},
"companyPage": {
"title": "設定"
},
"accountPage": {
"title": "帳戶"
},
"heatmapPage": {
"title": "熱圖"
},
"logPage": {
"title": "活動記錄"
},
"dashboardPage": {
"title": "儀表板"
},
"insightsPage": {
"title": "觀察"
},
"schedulePage": {
"title": "時間表"
},
"calendarPage": {
"title": "日曆"
},
},
"cn": {
"unit": {
"degree": "°C",
"ppm": "ppm",
"ugm3": "μg/m³",
"hour": "小时",
"minute": "分钟",
"lx": "lx",
"kwh": "kWh"
},
"role": {
"DASHBOARD_ADMIN": "仪表板管理员",
"TENANT_USER": "用户",
"ZONE_ADMIN": "楼层管理员",
"TENANT_ADMIN": "管理员",
"LANDLORD_USER": "房东用户",
"LANDLORD_ADMIN": "房东管理员",
"SUPERUSER": "超级系统管理员",
"UNKNOWN": "不明"
},
"label": {
"disconnected": "离线",
"minutes": "分分钟",
"adjustingTemperature": "温度调整中...请稍候",
"onUntil": "开启至${time}",
"onWithoutTime": "设备已经开启",
"workstationName": "${fName}${lName}的位置",
"fullName": "${fName}${lName}",
"search": "搜索",
"noDept": "没有部门",
"dashboard": "仪表板",
"location": "地点",
"company": "公司",
"scene": "场景",
"calendar": "日历",
"schedule": "行程",
"account": "帐户",
"log": "活动记录",
"heatmap": "热图",
"dimLv": "亮度",
"yesterday": "昨天",
"last7Days": "过去 7 天",
"last28Days": "过去 28 天",
"system": "系统",
"publicHolidays": "公众假期",
"selectAll": "全选",
"to": "${from}至${to}",
"temperature": "温度",
"humidity": "相对湿度",
"co2": "二氧化碳",
"pm25": "PM2.5",
"hotDesk": "共用位置",
"sent": "发送成功！",
"copied": "复制成功！",
"alwaysOn": "设备常开",
"high": "高",
"medium": "中",
"low": "低",
"auto": "自动",
"ADMIN": "系统管理员",
"ZONE_ADMIN": "楼层管理员",
"SUPERUSER": "超级系统管理员",
"USER": "使用者",
"LANDLORD_USER": "地主用户",
"UNKNOWN_ROLE": "不明",
"unknownUser": "不明用户",
"pwdContain": "密码必须包含：",
"pwdLowerCase": "至少一个小写字母",
"pwdUpperCase": "至少一个大写字母",
"pwdSpecialChar": "至少一个特殊字符",
"pwdNumber": "至少一个数字",
"pwdMin": "至少${min}个字符",
"pwdMinMax": "${min}至${max}个字符"
},
"nodeType": {
"company": "公司",
"floor": "楼层",
"room": "公共区域",
"workstation": "位置"
},
"applicationType": {
"light": "灯",
"aircon": "空调",
"gateway": "闸道",
"button": "按钮",
"iaqSensor": "室内空气质素传感器",
"lightSensor": "光感应器",
"motionSensor": "感应传感器",
"fan": "风扇",
"thermometer": "温度计",
"energyMeter": "电表",
"waterMeter": "水表",
"switch": "墙壁开关",
"door": "门",
"unknown": "不明"
},
"scheduleCtrl": {
"autoDesc": "设备将自动开启",
"manualDesc": "需要手动开启设备",
"isAltSat": "长短周",
"isLongWeek": "本周是长周",
"autoLegend": "自动开启",
"manualLegend": "手动开启",
"priority": "优先次序",
"custDayEndActionOff": "设备将在当天结束时自动关闭",
"custDayEndActionNone": "设备的状态将不会在当天结束时改变"
},
"uploadCtrl": {
"upload": "上载",
"invalidFormat": "请选择 TXT/CSV 档案。"
},
"button": {
"turnOn": "开启",
"allOn": "全部开启",
"turnOff": "关闭",
"allOff": "全部关闭",
"leaving": "离开",
"here": "我在这里",
"moreAction": "我想...",
"keepOn": "我会多留一会",
"confirm": "确认",
"save": "保存",
"cancel": "取消",
"back": "返回",
"add": "新增",
"edit": "编辑",
"delete": "删除",
"remove": "消除",
"enable": "生效",
"disable": "停止",
"signIn": "登录",
"next": "下一步",
"reset": "重设",
"goSignIn": "回到登入页面",
"done": "完毕",
"createNewWs": "创建新的位置",
"createNewRm": "建立新的公共区域",
"create": "创建",
"importAccounts": "上载帐户",
"import": "上载",
"manageDepartment": "编辑部门",
"manage": "编辑",
"addDepartment": "新增部门",
"addAccount": "新增帐户",
"reinvite": "重新邀请",
"invite": "邀请",
"addInvite": "新增及邀请",
"inviteSelected": "发送邀请",
"deleteAccount": "删除该帐户",
"createFloorChild": "创建群组",
"modeSettings": "模式设定",
"apply": "套用",
"copy": "克隆",
"selectWs": "选择位置",
"selectLater": "待会再选择",
"warmer": "暖一点",
"cooler": "凉一点",
"extendRoom": "延长",
"editSchedule": "编辑日程",
"createSchedule": "创建时间表",
"deleteSchedule": "删除行程",
"createScene": "创建场景",
"deleteScene": "删除场景",
"removeFromScene": "从场景中移除",
"selectScene": "选择场景",
"generateApiKey": "生成API密钥",
"deleteSpecialDay": "删除特别日子",
"addSpecialDay": "新增特别日子",
"selectSpecialDay": "选择特别日子",
"editTimeslot": "编辑时段",
"deactivateScene": "停用场景",
"releaseWs": "释放位置",
"delegate": "委托 (${count})",
"switchHotdesk": "转到共用位置",
"switchWorkstation": "转到其他位置",
"selectOwners": "分配用户",
"editPolygon": "编辑位置",
"deviceView": "设备视图",
"boundaryView": "边界视图"
},
"disconnectWin": {
"title": "离线的设备",
"disconnectFound": "找到${count}离线的设备",
"actionMsg": "请先关闭然后再开启墙上的电源使设备重新连接。如果还是无效，请联系你的系统管理员。"
},
"delegateWsWin": {
"title": "委托位置",
"ws": "位置",
"delegateTo": "委托给"
},
"error": {
"checkPwd": "请再次检查您的密码。",
"generalResetPwdFail": "无法重设密码。",
"generalActivateFail": "无法启动帐户。",
"invalidPwd": "密码应包含8-16个字符，最少1个数字和1个字母。",
"invalidPwdAdv": "",
"invalidLogin": "对不起，你的电邮地址和/或密码不正确，请重试。",
"generalForgotFail": "无法发送重设密码的电邮。",
"generalLoginFail": "无法登入。",
"generalNameFail": "名称无效。",
"generalUpdateAccountFail": "无法更改帐户资料。",
"accountExist": "相同的电邮地址已被使用。",
"generalSendEmailFail": "无法发送电邮。",
"invalidEmailFormat": "无效的电邮地址。",
"generalDelItemFail": "无法删除项目。",
"generalDelWsFail": "无法删除位置。",
"generalDelRmFail": "无法删除公共区域。",
"generalDelAccFail": "无法删除帐户。",
"generalDelDeptFail": "无法删除部门。",
"generalSaveFail": "无法储存变更。",
"general": "无法处理，请刷新浏览器，然后再试一次。",
"generalControlFail": "无法控制设备，请刷新浏览器，然后再试一次。",
"noPortalAccess": "对不起，您的帐户没有访问此页面的访问权限。请使用其他帐户重试。",
"ownerHasWorkstation": "此用户已被分配到另一个位置。",
"invalidMsId": "无效的微软(Microsoft)365租使用者识别码。",
"invalidBookingEmail": "预订房间系统没有此电邮地址。",
"logMissingData": "一些设备的数据不齐全，计算结果可能不准确。",
"generalAddDelegateFail": "无法新增委托至${email}。",
"generalRemoveDelegateFail": "无法取消对${email}的委托。",
"airconNotFound": "无法在这楼层找到此空调。",
"generalAssignOwnerFail": "无法分配用户到此位置。",
"generalAssignZoneAdminFail": "无法分配楼层管理员到此楼层。",
"generalDelZoneAdminFail": "无法删除楼层管理员分配。",
"generalDelZoneFail": "",
"generalDelOwnerFail": "无法删除用户。",
"missingDeviceInFloor": "找不到此楼层的一些设备，你可能无法更改群组，请联系你的系统管理员。",
"alwaysOnNoSchedule": "此群组所在的楼层已设为“设备常开”，你无法为其分配时间表。"
},
"generalUpdatePolygonFail": "无法更新位置。",
"header": {
"en": "English",
"zh": "繁體中文",
"cn": "简体中文",
"enShort": "Eng",
"zhShort": "繁",
"cnShort": "简",
"logout": "登出"
},
"lang": {
"EN_US": "English",
"ZH_HANT": "繁體中文",
"ZH_HANS": "简体中文",
"JA_JA": "日本語",
"TH_TH": "แบบไทย"
},
"profile": {
"title": "个人档案",
"cardNo": "门禁卡号码",
"account": "帐户",
"password": "密码",
"email": "电邮",
"fName": "名",
"lName": "姓氏",
"currentPwd": "目前密码",
"newPwd": "新密码",
"confirmPwd": "确认密码",
"accessRight": "权限",
"role": "职能",
"department": "部门",
"workstation": "位置",
"noWorkstation": "没有指定位置",
"pwdExpired": "密码已过期",
"pwdExpiredDesc": "你的密码已过期，请立即更改密码。",
"notification": "通知",
"emailRequestNotifiation": "当用户有大量较凉快/较温暖的请求时，给我发电子邮件",
"scheduleOffNotification": "在我的位置预定关灯时间前5分钟通知我",
"emailDeviceOfflineNotifiation": "当有一个或多个设备出现问题时，给我发电子邮件"
},
"dashboard": {
"panel": {
"workstationOnUntil": "你的位置会开启至${time}",
"workstationOnWithoutTime": "你的位置已经开启",
"workstationOnAt": "你的位置将在${time}开启",
"welcome": "欢迎${name}！",
"selectWsReminder": "你没有位置，但你可以现在选择一个！",
"switchWsReminder": "你已经有位置，但你还可以转换到其他位置。",
"workstationOnReminder": "当你离开时请记得关闭你的设备！",
"workstationOffReminder": "开启你的设备。",
"workstationDisconnectMsg": "你的部分设备离线。请先关闭再开启电源重使设备新连接。"
},
"card": {
"status": "地位",
"devices": "设备",
"viewAllWs": "查看所有位置",
"viewAll": "看全部",
"titleScene": "场景",
"titleWs": "位置",
"yourWs": "你的位置",
"titleRoom": "公共区域",
"titleRecent": "最近的控制",
"titleVisitor": "访客",
"inviteVisitor": "邀请访客",
"visitorInfo": "访客资料",
"noVisitorDesc": "如果有访客来到你的办公室，你可以在此添加他们让他们可以控制位置的开关。",
"email": "电邮地址",
"startTime": "开始时间",
"endTime": "结束时间",
"tabOn": "所有开启的群组",
"tabOff": "所有关闭的群组",
"downloadAppTitle": "下载应用程式",
"downloadAppDesc": "下载流动应用程序来控制你的办公室环境！",
"noWorkstationDesc": "你还没有被分配到一个位置",
"ios": "iOS",
"android": "Android",
"huawei": "Huawei",
"heatIndex": "温度指数: ${hi}",
"revokeVisitor": "删除访客",
"revokeDesc": "你确定要删除这个访客吗？",
"accessCode": "验证码"
},
"selectWsWin": {
"selectWsDesc": "请在下面选择一个可用的位置。"
},
"timerWin": {
"onUntil": "你的位置会开启至"
},
"schedulePopup": {
"title": "行程"
},
},
"company": {
"title": "公司",
"habitap": "Habitap 整合",
"habitapId": "Habitap 用户识别码",
"msTenantId": "微软(Microsoft)365租使用者识别码",
"apiKey": "API密钥",
"oauthProvider": "开放授权(OAuth)提供者",
"msSubscription": "微软(Microsoft)日历订阅",
"extension": "延长时间",
"defaultExtend": "预设延长时间",
"maxExtend": "最大延长时间",
"heatIndexDefinition": "温度指数定义",
"defineHeatIndex": "定义温度指数",
"heatIndex": "温度指数",
"temperature": "温度",
"humidity": "相对湿度",
"allowedDomain": "允许的网域",
"popup": {
"date": "日期",
"addAllowedDomain": "新增允许网域",
"addOAuthProvider": "新增开放授权(OAuth)提供者",
"google": "谷歌(Google)",
"microsoft": "微软(Microsoft)"
},
"mircosoftId": "微软(Microsoft)365租使用者识别码",
"googleId": "谷歌客户端识别码",
"deleteDomainTitle": "删除网域",
"deleteDomainDesc": "你确定要删除这个网域？",
"deleteOAuthTitle": "删除开放授权(OAuth)提供者",
"deleteOAuthDesc": "你确定要删除这个开放授权(OAuth)提供者？",
"deleteSubscriptionTitle": "删除订阅",
"deleteSubscriptionDesc": "你确定要删除这个订阅？",
"advancedPwd": "进阶密码验证",
"disablePwd": "用账户名称/密码登入",
"msPermission": "受权使用微软(Microsoft)日历",
"consentLink": "受权链结",
"allowSelfReg": "容许用户注册",
"viewOthers": "容许查看他人位置",
"securitySection": "身份和安全性",
"configurationSection": "基本设定",
"integrationSection": "整合",
"enableVisitor": "容许访客使用",
"titleVisitor": "启用访客控制",
"visitorDesc1": "此功能允许你的员工邀请访客，使用位于位置上预先印刷的QR码开/关位置的灯和/或空调。你公司中的所有用户将能够使用此功能来邀请访客。",
"visitorDesc2": "要设置此工作流程，你将需要为每个访客可能需要使用的位置准备QR码（它们将没有TEP的帐户，因此他们需要通过QR码扫描访问系统）。你可以在地点页面看到所有位置，在位置的详细信息下找到该位置的QR码。",
"visitorDesc3": "基本工作流程：",
"visitorDesc4Head": "邀请访客",
"visitorDesc4": " - 此功能可以在TEP网页版的仪表板上找到。输入访客的电邮地址和他们将到办公室的日期/时间。他们将收到邀请电邮，电邮包括唯一的访问代码，该代码仅在指定的时间段内有效。",
"visitorDesc5Head": "访客到达",
"visitorDesc5": " - 访客将使用他们的手机扫描他们选择的位置上的QR码。他们的手机将打开一个网页并询问邀请电邮中提供的访问代码。当输入此代码时，灯和/或空调将打开/关闭。",
"defaultLanguage": "预设语言"
},
"calendar": {
"title": "日历",
"specialDays": "特别日子",
"showCity": "显示地区公众假期",
"spDayName": "特别日子名称",
"deleteSpDayDesc": "你确定要删除这个特别日子？",
"deleteSpDayTitle": "删除特别日子",
"selectDateDesc": "点击日历来选择日期。"
},
"location": {
"title": "地点",
"treeTitleFloorPlan": "平面图",
"treeTitleFloorChild": "群组",
"treeTitleCorridor": "走廊",
"titleDeleteWorkstation": "删除这个位置",
"titleDeleteRoom": "删除该公共区域",
"deleteFloorChildDesc": "你确定要删除该群组？",
"locationName": "姓名",
"defaultTemperature": "预设温度",
"heatIndexIn": "温度指数(时间表内)",
"heatIndexOut": "温度指数(时间表外)",
"controlLogicIn": "控制逻辑 (时间表外)",
"controlLogicOut": "控制逻辑 (时间表内)",
"controlLogicCo2": "控制逻辑 (二氧化碳浓度)",
"co2concentration": "二氧化碳浓度",
"logicGTE": "大于或等于",
"logicLTE": "小于或等于",
"logicEQ": "等于",
"logicNA": "无控制逻辑",
"workingHrs": "工作时间",
"adminOnly": "只限管​​理员",
"sharable": "容许多个用户",
"useCompSchedule": "使用公司预设时间表",
"createFloorChildDesc": "你尚未创建任何群组。点击下面的按钮来创建公共区域和位置。",
"corridorDesc": "选择不同时段的走廊设置。",
"corridorWorkingHrs": "工作时间内",
"corridorNonWorkingHrs": "工作时间外",
"mixOnOffDesc": "此选项是舒适性和节能的最佳组合。",
"allOnDesc": "此选项是最节能的，但保持整个办公室有足够亮度。",
"allOffDesc": "通过关闭所有走廊灯，此选项节约了大部分能量。",
"mixOnOff": "混合开关",
"allOn": "全部开启",
"allOff": "全部关闭",
"owner": "用户",
"corridorDevices": "走廊设备",
"titleCustLighingPlan": "自定走廊灯",
"custLighingPlanDesc": "选择工作时间内想开启的走廊设备。",
"deviceWillOn": "${count}个设备将会开启",
"deviceWillOff": "${count}个设备将会关闭",
"floorPlan": "平面图",
"uploadedFloorPlan": "上载平面图：",
"serialNo": "序号:",
"editFloorChild": "编辑群组",
"addDeviceToFloorChild": "点击平面图上的设备来添加/删除至此群组。",
"numOfChildInFloor": "${count}此楼层的群组。",
"createChildDesc": "选择设备来创建新群组。",
"floorChildName": "群组名称",
"defaultName": "预设名称",
"titleEditFloor": "编辑楼层",
"someoneWorkstation": "${name}的位置",
"createLbl1": "你想创建一个",
"createLbl2": "或",
"outlook": "Outlook",
"habitap": "Habitap",
"habitapExternalId": "Facility",
"outlookExternalId": "电邮",
"managedBy": "管理者",
"bookingSystem": "Habitap",
"temperatureDuration": "温度变化时间",
"coolerDelta": "温度下降值",
"warmerDelta": "温度上升值",
"heatMode": "转换HVAC到热模式",
"deviceProperty": "设备属性",
"qrCodeDesc": "你可以扫描下面的二维码来开启或关闭此群组。",
"forUser": "供用户使用",
"forVisitor": "供访客使用",
"qrCode": "二维码",
"floorAvg": "楼层平均值",
"deleteOwnerTitle": "删除用户分配",
"deleteOwnerDesc": "你确定要删除这位项用户分配吗？",
"zoneAdmin": "楼层管理员",
"deleteZoneAdminTitle": "删除楼层管理员分配",
"deleteZoneAdminDesc": "你确定要删除这位项楼层管理员分配吗？",
"deleteExternalId": "删除预订系统链结",
"deleteExternalIdDesc": "你确定要删除这公共区域与预订系统的链结吗?",
"fanSpeed": "风速",
"offDelay": "延迟关闭",
"coordinates": "边界座标"
},
"schedule": {
"title": "时间表",
"editTitle": "分配时间表",
"dragDropCardDesc": "拖放群组卡到时间表。",
"dragTimeslotDesc": "点击并拖动滑鼠以创建一个时段。",
"titleCreateSch": "建立新的时间表",
"titleEditSch": "编辑日程",
"todayNoSchedule": "今天没有时间表",
"scheduleName": "姓名",
"schedule": "每周时间表",
"location": "地点",
"specialDay": "特别日子时间表",
"deleteScheduleDesc": "如果删除这个时间表，在重新分配时间表前所有位置和公共区域将没有时间表。",
"groupNum": "群组：${count}",
"noScheduleDesc": "你还没有创建任何时间表。点击下面的按钮来创建你的第一个时间表。",
"selectSpDayDesc": "选择会影响此时间表的特别日子",
"allFloor": "所有楼层",
"scope": "范围"
},
"log": {
"title": "活动记录",
"onDuration": "总开启时间：",
"onMinutes": "${minutes}分钟",
"timeRange": "时间：",
"time": "时间",
"action": "行动",
"actionBy": "行动者",
"remark": "备注",
"on": "开",
"off": "关",
"coolerRequest": "要求凉一点：",
"warmerRequest": "要求暖一点：",
"noRequest": "无要求",
"airconRequestTitle": "温度要求",
"usageTitle": "使用记录",
"allGroups": "所有群组",
"allFloors": "所有楼层",
"rooms": "公共区域",
"workstations": "位置",
"actionDesc": {
"COOLER": "凉些",
"WARMER": "暖些",
"EXTEND": "延长",
"CHECKIN": "开启",
"CHECKOUT": "关闭",
"NORMAL": "回复正常温度",
"REINSTATE": "恢复",
"UNKNOWN": "不明",
"ZONEON": "楼层全开",
"ZONEOFF": "楼层全关",
"DIMMING": "调节亮度"
},
"downloadCsv": "下载CSV (${from}至${to})",
"containUnknown": "${name}有不明行动记录，此群组的开关时间计算可能不准确。",
"triggerType": {
"CARD": "门禁卡",
"TIMETABLE": "行程",
"USER": "使用者",
"API": "API",
"SCENE": "场景",
"BUTTON": "按钮",
"INVALID": "无效",
"RESERVATION": "预订",
"CONDITION": "状态",
"MULTIGANG": "墙上开关",
"VISITOR": "访客",
"SYSTEM": "系统",
"OCCUPANCY_SENSOR": "感应传感器",
"LIGHT_SENSOR": "光感应器",
"BOOKING_SYSTEM": "预订房间系统"
},
},
"account": {
"title": "帐户",
"department": "部门",
"name": "姓名",
"email": "电邮",
"role": "职能",
"cardNo": "门禁卡",
"noWorkstation": "这名员工还没有被分配到一个位置。",
"addZone": "分配楼层",
"addAccountWin": {
"titleAddAcc": "新增帐户",
"titleEditAcc": "编辑帐户",
"cardNumber": "门禁卡号码",
"fName": "名",
"lName": "姓氏"
},
"deleteUserTitle": "删除帐户",
"deleteUserDesc": "您确定要删除该帐户吗？",
"deleteDeptTitle": "删除部门",
"deleteDeptDesc": "您确定要删除该部门吗？",
"downloadTmpl": "你可以下载这里的",
"here": "模板文件",
"inviteAllWin": {
"title": "邀请帐户",
"desc": "你选择了${count}个账户。你确定要发送邀请电邮给他们？"
},
"deleteZoneTitle": "删除楼层",
"deleteZoneDesc": "你确定要删除这楼层吗？"
},
"scene": {
"title": "场景",
"noSceneDesc": "你还没有创建任何场景。点击下面的按钮来创建你的第一个场景。",
"win": {
"titleCreate": "创建场景",
"titleEdit": "编辑场景",
"name": "姓名",
"floor": "楼层"
},
"addDeviceToScene": "点击设备将其添加到场景。",
"numOfDeviceInScene": "此场景有${count}个设备。",
"ctrlLogic": "光传感器控制逻辑",
"illuminance": "照度"
},
"heatmap": {
"title": "热图",
"coolerRequest": "要求凉一点：",
"warmerRequest": "要求暖一点：",
"noRecord": "没有找到记录。",
"action": {
"cooler": "凉些",
"warmer": "暖些"
},
"actionBy": "请求者:",
"corridor": "走廊",
"groupCovered": "覆盖群组：",
"record": "记录：",
"noAirconDesc": "这楼层没有任何可调节温度的空调单元﹐请于活动记录查看开关记录。",
"requestCount": "要求次数",
"defaultTemperature": "预设温度:",
"temperatureRange": "温度变化:",
"averageTemperature": "平均温度:",
"chartTitle": "温度记录",
"setPointChange": "调整预设温度"
},
"mainPage": {
"title": "控制"
},
"locationPage": {
"title": "位置/设备"
},
"scenePage": {
"title": "场景"
},
"companyPage": {
"title": "设置"
},
"accountPage": {
"title": "帐户"
},
"heatmapPage": {
"title": "热图"
},
"logPage": {
"title": "活动记录"
},
"dashboardPage": {
"title": "仪表板"
},
"insightsPage": {
"title": "观察"
},
"schedulePage": {
"title": "时间表"
},
"calendarPage": {
"title": "日历"
},
},
"th": {
"unit": {
"degree": "องศาเซลเซียส",
"ppm": "ppm",
"ugm3": "μg/m³",
"hour": "ชั่วโมง",
"minute": "นาที",
"lx": "lx",
"kwh": "kWh"
},
"role": {
"DASHBOARD_ADMIN": "ผู้ดูแลระบบแดชบอร์ด",
"TENANT_USER": "ผู้ใช้",
"ZONE_ADMIN": "ผู้ดูแลระบบโซน",
"TENANT_ADMIN": "ผู้ดูแลระบบ",
"LANDLORD_USER": "ผู้ใช้เจ้าของบ้าน",
"LANDLORD_ADMIN": "ผู้ดูแลระบบเจ้าของบ้าน",
"SUPERUSER": "ผู้ใช้ขั้นสูง",
"UNKNOWN": "ไม่ทราบ"
},
"label": {
"disconnected": "ตัดการเชื่อมต่อแล้ว",
"minutes": "นาที",
"adjustingTemperature": "กำลังปรับอุณหภูมิ...โปรดรอสักครู่",
"onUntil": "เปิดจนถึง ${time}",
"onWithoutTime": "อุปกรณ์เปิดอยู่",
"workstationName": "เวิร์กสเตชันของ ${fName} ${lName}",
"fullName": "${fName} ${lName}",
"search": "ค้นหา",
"noDept": "ไม่มีแผนก",
"dashboard": "แดชบอร์ด",
"location": "ที่ตั้ง",
"company": "บริษัท",
"scene": "ฉาก",
"calendar": "ปฏิทิน",
"schedule": "กำหนดการ",
"account": "บัญชี",
"log": "บันทึกกิจกรรม",
"heatmap": "แผนที่ความร้อน",
"dimLv": "ระดับสลัว",
"yesterday": "เมื่อวาน",
"last7Days": "7 วันที่ผ่านมา",
"last28Days": "28 วันที่ผ่านมา",
"system": "ระบบ",
"publicHolidays": "วันหยุดนักขัตฤกษ์",
"selectAll": "เลือกทั้งหมด",
"to": "${จาก} ถึง ${ถึง}",
"temperature": "อุณหภูมิ",
"humidity": "ความชื้นสัมพัทธ์",
"co2": "คาร์บอนไดออกไซด์",
"pm25": "PM2.5",
"hotDesk": "โต๊ะร้อน",
"sent": "ส่งแล้ว!",
"copied": "คัดลอก!",
"alwaysOn": "เปิดอยู่เสมอ",
"high": "สูง",
"medium": "ปานกลาง",
"low": "ต่ำ",
"auto": "อัตโนมัติ",
"ADMIN": "ผู้ดูแลระบบ",
"ZONE_ADMIN": "ผู้ดูแลระบบโซน",
"SUPERUSER": "ผู้ใช้ขั้นสูง",
"USER": "ผู้ใช้",
"LANDLORD_USER": "ผู้ใช้เจ้าของบ้าน",
"UNKNOWN_ROLE": "ไม่ทราบ",
"unknownUser": "ผู้ใช้ที่ไม่รู้จัก",
"pwdContain": "รหัสผ่านจะต้องมี:",
"pwdLowerCase": "อักษรตัวพิมพ์เล็กอย่างน้อย 1 ตัว",
"pwdUpperCase": "อักษรตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว",
"pwdSpecialChar": "อักขระพิเศษอย่างน้อย 1 ตัว",
"pwdNumber": "อย่างน้อย 1 หมายเลข",
"pwdMin": "อย่างน้อย ${min} ตัวอักษร",
"pwdMinMax": "${min} ถึง ${max} อักขระ"
},
"nodeType": {
"company": "บริษัท",
"floor": "พื้น",
"room": "พื้นที่ส่วนกลาง",
"workstation": "เวิร์กสเตชัน"
},
"applicationType": {
"light": "แสงสว่าง",
"aircon": "เครื่องปรับอากาศ",
"gateway": "เกตเวย์",
"button": "ปุ่ม",
"iaqSensor": "เซ็นเซอร์ไอเอคิว",
"lightSensor": "เซ็นเซอร์วัดแสง",
"motionSensor": "เซ็นเซอร์ตรวจจับการเข้าใช้",
"fan": "พัดลม",
"thermometer": "เทอร์โมมิเตอร์",
"energyMeter": "เครื่องวัดพลังงาน",
"waterMeter": "มิเตอร์น้ำ",
"switch": "สวิตช์ติดผนัง",
"door": "ประตู",
"unknown": "ไม่ทราบ"
},
"scheduleCtrl": {
"autoDesc": "อุปกรณ์จะเปิดขึ้นโดยอัตโนมัติ",
"manualDesc": "ต้องมีการเปิดด้วยตนเองเพื่อเปิดใช้งานเซสชัน",
"isAltSat": "วันเสาร์ทางเลือก",
"isLongWeek": "สัปดาห์นี้เป็นสัปดาห์ที่ยาวนาน",
"autoLegend": "เปิดอัตโนมัติ",
"manualLegend": "เปิดด้วยตนเอง",
"priority": "ลำดับความสำคัญ",
"custDayEndActionOff": "อุปกรณ์จะปิดโดยอัตโนมัติเมื่อสิ้นสุดวัน",
"custDayEndActionNone": "สถานะของอุปกรณ์จะไม่เปลี่ยนแปลงเมื่อสิ้นสุดวัน"
},
"uploadCtrl": {
"upload": "อัปโหลด",
"invalidFormat": "โปรดเลือกไฟล์ TXT/CSV"
},
"button": {
"turnOn": "เปิด",
"allOn": "เปิดทั้งหมด",
"turnOff": "ปิด",
"allOff": "ปิดทั้งหมด",
"leaving": "เพื่อออกเดินทาง",
"here": "ฉันอยู่ที่นี่",
"moreAction": "ฉันต้องการ...",
"keepOn": "ฉันอยู่ที่นี่",
"confirm": "ยืนยัน",
"save": "บันทึก",
"cancel": "ยกเลิก",
"back": "กลับ",
"add": "เพิ่ม",
"edit": "แก้ไข",
"delete": "ลบ",
"remove": "ลบ",
"enable": "เปิดใช้งาน",
"disable": "ปิดการใช้งาน",
"signIn": "เข้าสู่ระบบ",
"next": "ต่อไป",
"reset": "รีเซ็ต",
"goSignIn": "กลับไปลงชื่อเข้าใช้",
"done": "เสร็จแล้ว",
"createNewWs": "สร้างเวิร์กสเตชันใหม่",
"createNewRm": "สร้างพื้นที่ส่วนกลางใหม่",
"create": "สร้าง",
"importAccounts": "นำเข้าบัญชี",
"import": "นำเข้า",
"manageDepartment": "จัดการแผนกต่างๆ",
"manage": "จัดการ",
"addDepartment": "เพิ่มแผนก",
"addAccount": "เพิ่มบัญชี",
"reinvite": "เชิญอีกครั้ง",
"invite": "เชิญ",
"addInvite": "เพิ่มและเชิญ",
"inviteSelected": "ส่งคำเชิญ",
"deleteAccount": "ลบบัญชีนี้",
"createFloorChild": "สร้างกลุ่ม",
"modeSettings": "การตั้งค่าโหมด",
"apply": "นำมาใช้",
"copy": "สำเนา",
"selectWs": "เลือกเวิร์กสเตชัน",
"selectLater": "เลือกทีหลัง",
"warmer": "อุ่นขึ้น",
"cooler": "คูลเลอร์",
"extendRoom": "ขยาย",
"editSchedule": "แก้ไขกำหนดการ",
"createSchedule": "สร้างกำหนดการ",
"deleteSchedule": "ลบกำหนดการ",
"createScene": "สร้างฉาก",
"deleteScene": "ลบฉาก",
"removeFromScene": "นำออกจากที่เกิดเหตุ",
"selectScene": "เลือกฉาก",
"generateApiKey": "สร้างคีย์ API",
"deleteSpecialDay": "ลบวันพิเศษ",
"addSpecialDay": "เพิ่มวันพิเศษ",
"selectSpecialDay": "เลือกวันพิเศษ",
"editTimeslot": "แก้ไขช่วงเวลา",
"deactivateScene": "ปิดการใช้งานฉาก",
"releaseWs": "ปล่อยเวิร์กสเตชัน",
"delegate": "ผู้รับมอบสิทธิ์ (${count})",
"switchHotdesk": "เปลี่ยนไปใช้โต๊ะส่วนกลาง",
"switchWorkstation": "สลับเวิร์กสเตชัน",
"selectOwners": "เลือกเจ้าของ",
"editPolygon": "แก้ไขสถานที่",
"deviceView": "มุมมองอุปกรณ์",
"boundaryView": "มุมมองขอบเขต"
},
"disconnectWin": {
"title": "อุปกรณ์ตัดการเชื่อมต่อ",
"disconnectFound": "พบอุปกรณ์ที่ยกเลิกการเชื่อมต่อ ${count} เครื่อง",
"actionMsg": "กรุณาปิดและเปิดสวิตช์ติดผนังเพื่อเชื่อมต่ออุปกรณ์อีกครั้ง หากไม่ได้ผล โปรดติดต่อผู้ดูแลระบบของคุณ"
},
"delegateWsWin": {
"title": "เวิร์กสเตชันมอบหมาย",
"ws": "เวิร์กสเตชัน",
"delegateTo": "มอบหมายให้"
},
"error": {
"checkPwd": "กรุณาตรวจสอบรหัสผ่านของคุณอีกครั้ง",
"generalResetPwdFail": "ไม่สามารถรีเซ็ตรหัสผ่านได้",
"generalActivateFail": "ไม่สามารถเปิดใช้งานบัญชีได้",
"invalidPwd": "รหัสผ่านควรประกอบด้วยอักขระ 8-16 ตัว อย่างน้อย 1 ตัวเลขและ 1 ตัวอักษร",
"invalidPwdAdv": "",
"invalidLogin": "ขออภัย อีเมลและ/หรือรหัสผ่านของคุณไม่ถูกต้อง โปรดลองอีกครั้ง",
"generalForgotFail": "ไม่สามารถส่งลิงก์รีเซ็ตได้",
"generalLoginFail": "ไม่สามารถลงชื่อเข้าใช้ได้",
"generalNameFail": "ชื่อไม่ถูกต้อง",
"generalUpdateAccountFail": "ไม่สามารถอัปเดตบัญชีได้",
"accountExist": "มีบัญชีที่ใช้อีเมลเดียวกันนี้อยู่แล้ว",
"generalSendEmailFail": "ไม่สามารถส่งอีเมลได้",
"invalidEmailFormat": "รูปแบบอีเมลไม่ถูกต้อง",
"generalDelItemFail": "ไม่สามารถลบรายการได้",
"generalDelWsFail": "ไม่สามารถลบเวิร์กสเตชันได้",
"generalDelRmFail": "ลบพื้นที่ส่วนกลางไม่ได้",
"generalDelAccFail": "ไม่สามารถลบบัญชีผู้ใช้ได้",
"generalDelDeptFail": "ไม่สามารถลบแผนกได้",
"generalSaveFail": "ไม่สามารถบันทึกการเปลี่ยนแปลงได้",
"general": "ไม่สามารถดำเนินการต่อได้ โปรดรีเฟรชเบราว์เซอร์ของคุณแล้วลองอีกครั้ง",
"generalControlFail": "ไม่สามารถควบคุมอุปกรณ์ได้ โปรดรีเฟรชเบราว์เซอร์ของคุณแล้วลองอีกครั้ง",
"noPortalAccess": "ขออภัย บัญชีของคุณไม่มีสิทธิ์เข้าถึงหน้านี้ โปรดลองอีกครั้งด้วยบัญชีอื่น",
"ownerHasWorkstation": "ผู้ใช้ที่เลือกถูกกำหนดให้กับเวิร์กสเตชันอื่นแล้ว",
"invalidMsId": "รหัสผู้เช่า Microsoft ไม่ถูกต้อง",
"invalidBookingEmail": "ไม่พบอีเมลในระบบการจอง",
"logMissingData": "อุปกรณ์บางชนิดมีข้อมูลหายไป ผลการคำนวณอาจคลาดเคลื่อนได้",
"generalAddDelegateFail": "ไม่สามารถมอบหมายเวิร์กสเตชันให้กับผู้ใช้ ${email}",
"generalRemoveDelegateFail": "ไม่สามารถเพิกถอนการมอบหมายจากผู้ใช้ ${email}",
"airconNotFound": "ชั้นนี้หาแอร์ด้านล่างไม่เจอ",
"generalAssignOwnerFail": "ไม่สามารถกำหนดเจ้าของได้",
"generalAssignZoneAdminFail": "",
"generalDelZoneAdminFail": "",
"generalDelZoneFail": "",
"generalDelOwnerFail": "ไม่สามารถลบเจ้าของได้",
"missingDeviceInFloor": "",
"alwaysOnNoSchedule": ""
},
"generalUpdatePolygonFail": "",
"header": {
"en": "English",
"zh": "繁體中文",
"cn": "简体中文",
"enShort": "Eng",
"zhShort": "繁",
"cnShort": "简",
"logout": "ออกจากระบบ"
},
"lang": {
"EN_US": "English",
"ZH_HANT": "繁體中文",
"ZH_HANS": "简体中文",
"JA_JA": "日本語",
"TH_TH": "แบบไทย"
},
"profile": {
"title": "ประวัติโดยย่อ",
"cardNo": "หมายเลขบัตรเข้าใช้งาน",
"account": "บัญชี",
"password": "รหัสผ่าน",
"email": "อีเมล",
"fName": "ชื่อ",
"lName": "นามสกุล",
"currentPwd": "รหัสผ่านปัจจุบัน",
"newPwd": "รหัสผ่านใหม่",
"confirmPwd": "ยืนยันรหัสผ่าน",
"accessRight": "สิทธิ์การเข้าถึง",
"role": "บทบาท",
"department": "แผนก",
"workstation": "เวิร์กสเตชัน",
"noWorkstation": "ไม่มีการกำหนดเวิร์กสเตชัน",
"pwdExpired": "รหัสผ่านหมดอายุ",
"pwdExpiredDesc": "รหัสผ่านของคุณหมดอายุแล้ว โปรดเปลี่ยนรหัสผ่านทันที",
"notification": "การแจ้งเตือน",
"emailRequestNotifiation": "ส่งอีเมลถึงฉันเมื่อผู้ใช้ส่งคำขอที่เย็นกว่า/อุ่นกว่าจำนวนมาก",
"scheduleOffNotification": "แจ้งให้ฉันทราบ 5 นาทีก่อนที่เวิร์กสเตชันของฉันจะถูกกำหนดเวลาปิด",
"emailDeviceOfflineNotifiation": "ส่งอีเมลถึงฉันเมื่ออุปกรณ์ควบคุมอย่างน้อยหนึ่งเครื่องมีปัญหา"
},
"dashboard": {
"panel": {
"workstationOnUntil": "เวิร์กสเตชันของคุณเปิดอยู่จนถึง ${time}",
"workstationOnWithoutTime": "เวิร์กสเตชันของคุณเปิดอยู่",
"workstationOnAt": "เวิร์กสเตชันของคุณจะเปิดเมื่อ ${time}",
"welcome": "ยินดีต้อนรับคุณ ${name}!",
"selectWsReminder": "คุณไม่มีเวิร์กสเตชัน แต่คุณสามารถเลือกได้ตอนนี้!",
"switchWsReminder": "คุณมีเวิร์กสเตชันอยู่แล้ว แต่คุณสามารถเปลี่ยนไปใช้เวิร์กสเตชันอื่นได้",
"workstationOnReminder": "อย่าลืมปิดเวิร์กสเตชันของคุณเมื่อคุณออกไป!",
"workstationOffReminder": "มาเปิดเวิร์กสเตชันของคุณกันเถอะ",
"workstationDisconnectMsg": "อุปกรณ์บางส่วนของคุณถูกตัดการเชื่อมต่อ โปรดปิดและเปิดสวิตช์ติดผนังเพื่อเชื่อมต่ออุปกรณ์เหล่านี้อีกครั้ง"
},
"card": {
"status": "สถานะ",
"devices": "อุปกรณ์",
"viewAllWs": "ดูเวิร์กสเตชันทั้งหมด",
"viewAll": "ดูทั้งหมด",
"titleScene": "ฉาก",
"titleWs": "เวิร์กสเตชัน",
"yourWs": "เวิร์กสเตชันของคุณ",
"titleRoom": "พื้นที่ส่วนกลาง",
"titleRecent": "การควบคุมล่าสุด",
"titleVisitor": "ผู้เยี่ยมชม",
"inviteVisitor": "เชิญผู้มาเยือน",
"visitorInfo": "รายละเอียดผู้เยี่ยมชม",
"noVisitorDesc": "หากผู้เยี่ยมชมมาที่สำนักงานของคุณ คุณสามารถอนุญาตให้พวกเขาควบคุมเวิร์กสเตชันได้โดยเพิ่มพวกเขาที่นี่",
"email": "อีเมล",
"startTime": "เวลาเริ่มต้น",
"endTime": "เวลาสิ้นสุด",
"tabOn": "เปิดกลุ่มทั้งหมด",
"tabOff": "ปิดกลุ่มทั้งหมด",
"downloadAppTitle": "ดาวน์โหลดแอป",
"downloadAppDesc": "ดาวน์โหลดแอปมือถือเพื่อควบคุมสภาพแวดล้อมในสำนักงานของคุณ!",
"noWorkstationDesc": "คุณไม่ได้รับมอบหมายเวิร์กสเตชัน",
"ios": "ไอโอเอส",
"android": "หุ่นยนต์",
"huawei": "Huawei",
"heatIndex": "ดัชนีความร้อน: $ {hi}",
"revokeVisitor": "ดัชนีความร้อน: $ {hi}",
"revokeDesc": "ดัชนีความร้อน: $ {hi}",
"accessCode": "ดัชนีความร้อน: $ {hi}"
},
"selectWsWin": {
"selectWsDesc": "โปรดเลือกเวิร์กสเตชันที่มีอยู่ด้านล่าง"
},
"timerWin": {
"onUntil": "เวิร์กสเตชันของคุณเปิดอยู่จนถึง"
},
"schedulePopup": {
"title": "กำหนดการ"
},
},
"company": {
"title": "บริษัท",
"habitap": "บูรณาการที่อยู่อาศัย",
"habitapId": "รหัสลูกค้า Habitap",
"msTenantId": "รหัสผู้เช่า Microsoft 365",
"apiKey": "คีย์เอพีไอ",
"oauthProvider": "ผู้ให้บริการ OAuth",
"msSubscription": "การสมัครปฏิทิน Microsoft",
"extension": "ส่วนขยาย",
"defaultExtend": "ระยะเวลาการขยายเริ่มต้น",
"maxExtend": "ระยะเวลาการขยายสูงสุด",
"heatIndexDefinition": "คำนิยาม ดัชนีความร้อน",
"defineHeatIndex": "กำหนดดัชนีความร้อน",
"heatIndex": "ดัชนีความร้อน",
"temperature": "อุณหภูมิ",
"humidity": "ความชื้นสัมพัทธ์",
"allowedDomain": "โดเมนที่ได้รับอนุญาต",
"popup": {
"date": "วันที่",
"addAllowedDomain": "เพิ่มโดเมนที่ได้รับอนุญาต",
"addOAuthProvider": "เพิ่มผู้ให้บริการ OAuth",
"google": "Google",
"microsoft": "ไมโครซอฟต์"
},
"mircosoftId": "รหัสผู้เช่า Microsoft",
"googleId": "รหัสไคลเอ็นต์ของ Google",
"deleteDomainTitle": "ลบโดเมน",
"deleteDomainDesc": "คุณแน่ใจหรือไม่ว่าต้องการลบโดเมนนี้",
"deleteOAuthTitle": "ลบผู้ให้บริการ OAuth",
"deleteOAuthDesc": "คุณแน่ใจหรือไม่ว่าต้องการลบผู้ให้บริการ OAuth นี้",
"deleteSubscriptionTitle": "ลบการสมัครสมาชิก",
"deleteSubscriptionDesc": "คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการสมัครปฏิทินนี้",
"advancedPwd": "การตรวจสอบรหัสผ่านขั้นสูง",
"disablePwd": "เข้าสู่ระบบด้วยชื่อผู้ใช้/รหัสผ่าน",
"msPermission": "การรวมปฏิทินของ Microsoft",
"consentLink": "ลิงค์ยินยอม",
"allowSelfReg": "อนุญาตให้ลงทะเบียนด้วยตนเอง",
"viewOthers": "ดูเวิร์กสเตชันอื่นๆ",
"securitySection": "ตัวตนและความปลอดภัย",
"configurationSection": "การกำหนดค่าพื้นฐาน",
"integrationSection": "บูรณาการ",
"enableVisitor": "",
"titleVisitor": "",
"visitorDesc1": "",
"visitorDesc2": "",
"visitorDesc3": "",
"visitorDesc4Head": "",
"visitorDesc4": "",
"visitorDesc5Head": "",
"visitorDesc5": "",
"defaultLanguage": "ภาษาเริ่มต้น"
},
"calendar": {
"title": "ปฏิทิน",
"specialDays": "วันพิเศษ",
"showCity": "แสดงวันหยุดนักขัตฤกษ์สำหรับ",
"spDayName": "ชื่อวันพิเศษ",
"deleteSpDayDesc": "คุณแน่ใจหรือไม่ว่าต้องการลบวันพิเศษนี้",
"deleteSpDayTitle": "ลบวันพิเศษ",
"selectDateDesc": "คลิกที่ปฏิทินเพื่อเลือกวันที่"
},
"location": {
"title": "สถานที่",
"treeTitleFloorPlan": "แผนผังชั้น",
"treeTitleFloorChild": "กลุ่ม",
"treeTitleCorridor": "ทางเดิน",
"titleDeleteWorkstation": "ลบเวิร์กสเตชันนี้",
"titleDeleteRoom": "ลบพื้นที่ส่วนกลางนี้",
"deleteFloorChildDesc": "คุณแน่ใจหรือไม่ว่าต้องการลบกลุ่มนี้?",
"locationName": "ชื่อ",
"defaultTemperature": "อุณหภูมิเริ่มต้น",
"heatIndexIn": "ดัชนีความร้อน (ตามกำหนดเวลา)",
"heatIndexOut": "ดัชนีความร้อน (นอกตาราง)",
"controlLogicIn": "ตรรกะการควบคุม (ตามกำหนดเวลา)",
"controlLogicOut": "ตรรกะการควบคุม (นอกกำหนดการ)",
"controlLogicCo2": "ตรรกะการควบคุม (ความเข้มข้นของ CO₂)",
"co2concentration": "ความเข้มข้นของCO₂",
"logicGTE": "มากกว่าหรือเท่ากับ",
"logicLTE": "ต่ำกว่าหรือเท่ากับ",
"logicEQ": "เท่ากับ",
"logicNA": "ไม่มีตรรกะการควบคุม",
"workingHrs": "ชั่วโมงการทำงาน",
"adminOnly": "ผู้ดูแลระบบเท่านั้น",
"sharable": "แชร์ได้",
"useCompSchedule": "ใช้กำหนดการเริ่มต้นของบริษัท",
"createFloorChildDesc": "คุณยังไม่ได้สร้างกลุ่มใดๆ คลิกปุ่มด้านล่างเพื่อสร้างพื้นที่ส่วนกลางและเวิร์กสเตชัน",
"corridorDesc": "เลือกการตั้งค่าทางเดินสำหรับช่วงเวลาต่างๆ",
"corridorWorkingHrs": "ในช่วงเวลาทำงานที่กำหนด",
"corridorNonWorkingHrs": "นอกเวลาทำงานที่กำหนด",
"mixOnOffDesc": "ตัวเลือกนี้เป็นส่วนผสมที่ลงตัวระหว่างความสะดวกสบายและการประหยัดพลังงาน",
"allOnDesc": "ตัวเลือกนี้ประหยัดพลังงานน้อยที่สุด แต่ช่วยให้ทั้งสำนักงานสว่าง",
"allOffDesc": "ตัวเลือกนี้ช่วยประหยัดพลังงานได้มากที่สุดโดยการปิดไฟทางเดินทั้งหมด",
"mixOnOff": "มิกซ์ เปิด ปิด",
"allOn": "เปิดทั้งหมด",
"allOff": "ปิดทั้งหมด",
"owner": "เจ้าของ",
"corridorDevices": "อุปกรณ์ทางเดิน",
"titleCustLighingPlan": "ปรับแต่งแสงทางเดิน",
"custLighingPlanDesc": "เลือกอุปกรณ์ทางเดินที่จะเปิดในช่วงเวลาทำงาน",
"deviceWillOn": "อุปกรณ์ ${count} เครื่องจะเปิดอยู่",
"deviceWillOff": "อุปกรณ์ ${count} เครื่องจะถูกปิด",
"floorPlan": "แผนผังชั้น",
"uploadedFloorPlan": "แผนผังชั้นที่อัปโหลด:",
"serialNo": "อนุกรม:",
"editFloorChild": "แก้ไขกลุ่ม",
"addDeviceToFloorChild": "คลิกอุปกรณ์บนแผนผังเพื่อเพิ่ม/ลบออกจากกลุ่มนี้",
"numOfChildInFloor": "${count} กลุ่มบนชั้นนี้",
"createChildDesc": "เลือกอุปกรณ์เพื่อสร้างกลุ่มใหม่",
"floorChildName": "ชื่อกลุ่ม",
"defaultName": "ชื่อเริ่มต้น",
"titleEditFloor": "แก้ไขชั้น",
"someoneWorkstation": "เวิร์กสเตชันของ ${name}",
"createLbl1": "คุณต้องการสร้างก",
"createLbl2": "หรือ",
"outlook": "Outlook",
"habitap": "Habitap",
"habitapExternalId": "Facility",
"outlookExternalId": "อีเมล",
"managedBy": "บริหารงานโดย",
"bookingSystem": "Habitap",
"temperatureDuration": "ระยะเวลาการเปลี่ยนแปลงอุณหภูมิ",
"coolerDelta": "คูลเลอร์เดลต้า",
"warmerDelta": "เดลต้าที่อบอุ่นกว่า",
"heatMode": "สลับ HVAC ไปที่โหมดทำความร้อน",
"deviceProperty": "คุณสมบัติของอุปกรณ์",
"qrCodeDesc": "คุณสามารถสแกนรหัส QR ด้านล่างเพื่อเช็คอินหรือชำระเงินกลุ่มนี้",
"forUser": "สำหรับผู้ใช้ทั่วไป",
"forVisitor": "สำหรับผู้มาเยือน",
"qrCode": "รหัส QR",
"floorAvg": "ค่าเฉลี่ยชั้น",
"deleteOwnerTitle": "ลบเจ้าของ",
"deleteOwnerDesc": "คุณแน่ใจหรือไม่ว่าต้องการลบเจ้าของรายนี้",
"zoneAdmin": "ผู้ดูแลระบบโซน",
"deleteZoneAdminTitle": "ลบผู้ดูแลโซน",
"deleteZoneAdminDesc": "คุณแน่ใจหรือไม่ว่าต้องการลบผู้ดูแลระบบโซนนี้",
"deleteExternalId": "ลบการเชื่อมโยงระบบการจอง",
"deleteExternalIdDesc": "คุณแน่ใจหรือไม่ว่าต้องการลบการเชื่อมโยงระบบการจองสำหรับพื้นที่ส่วนกลางนี้",
"fanSpeed": "ความเร็วพัดลม",
"offDelay": "ปิดล่าช้า",
"coordinates": "พิกัดเขตแดน"
},
"schedule": {
"title": "กำหนดการ",
"editTitle": "แก้ไขการมอบหมายกำหนดการ",
"dragDropCardDesc": "ลากและวางการ์ดกลุ่มควบคุมเพื่อกำหนดให้กับกำหนดการ",
"dragTimeslotDesc": "คลิกและลากเพื่อสร้างช่วงเวลา",
"titleCreateSch": "สร้างกำหนดการใหม่",
"titleEditSch": "แก้ไขกำหนดการ",
"todayNoSchedule": "วันนี้ไม่มีกำหนดการ",
"scheduleName": "ชื่อ",
"schedule": "ตารางรายสัปดาห์",
"location": "ที่ตั้ง",
"specialDay": "ตารางวันพิเศษ",
"deleteScheduleDesc": "หากคุณลบกำหนดการนี้ เวิร์กสเตชันและพื้นที่ส่วนกลางทั้งหมดจะไม่มีกำหนดการจนกว่าจะมีการมอบหมายใหม่",
"groupNum": "กลุ่ม: ${count}",
"noScheduleDesc": "คุณยังไม่ได้สร้างกำหนดการใดๆ คลิกปุ่มด้านล่างเพื่อสร้างกำหนดการแรกของคุณ",
"selectSpDayDesc": "เลือกวันพิเศษที่จะใช้กับกำหนดการนี้",
"allFloor": "ทุกชั้น",
"scope": "ขอบเขต"
},
"log": {
"title": "บันทึกกิจกรรม",
"onDuration": "ระยะเวลาเปิดรวม:",
"onMinutes": "${นาที} นาที",
"timeRange": "เวลา:",
"time": "เวลา",
"action": "การกระทำ",
"actionBy": "โดย",
"remark": "",
"on": "บน",
"off": "ปิด",
"coolerRequest": "คำขอคูลเลอร์:",
"warmerRequest": "คำขอที่อบอุ่น:",
"noRequest": "ไม่มีการร้องขอ",
"airconRequestTitle": "ขอแอร์",
"usageTitle": "บันทึกการใช้งาน",
"allGroups": "ทุกกลุ่ม",
"allFloors": "ทุกชั้น",
"rooms": "พื้นที่ส่วนกลาง",
"workstations": "เวิร์กสเตชัน",
"actionDesc": {
"COOLER": "คูลเลอร์",
"WARMER": "อุ่นขึ้น",
"EXTEND": "ขยาย",
"CHECKIN": "เช็คอิน",
"CHECKOUT": "ชำระเงิน",
"NORMAL": "กลับสู่ค่าเริ่มต้น",
"REINSTATE": "คืนสถานะ",
"UNKNOWN": "ไม่ทราบ",
"ZONEON": "เปิดโซน",
"ZONEOFF": "ปิดโซน",
"DIMMING": "ลดแสง"
},
"downloadCsv": "ดาวน์โหลด CSV (${from} ถึง ${to})",
"containUnknown": "${name} มีการกระทำที่ไม่รู้จัก ระยะเวลาเปิด/ปิดสำหรับกลุ่มนี้อาจไม่ถูกต้อง",
"triggerType": {
"CARD": "การควบคุมการเข้าถึง",
"TIMETABLE": "กำหนดการ",
"USER": "ผู้ใช้",
"API": "เอพีไอ",
"SCENE": "ฉาก",
"BUTTON": "ปุ่ม",
"INVALID": "ไม่ถูกต้อง",
"RESERVATION": "การจอง",
"CONDITION": "เงื่อนไข",
"MULTIGANG": "สวิตช์ติดผนัง",
"VISITOR": "ผู้มาเยือน",
"SYSTEM": "ระบบ",
"OCCUPANCY_SENSOR": "เซ็นเซอร์ตรวจจับการเข้าใช้",
"LIGHT_SENSOR": "เซ็นเซอร์วัดแสง",
"BOOKING_SYSTEM": "ระบบการจอง"
},
},
"account": {
"title": "บัญชี",
"department": "แผนก",
"name": "ชื่อ",
"email": "อีเมล",
"role": "บทบาท",
"cardNo": "เข้าถึงบัตร",
"noWorkstation": "พนักงานคนนี้ไม่ได้ถูกกำหนดให้กับเวิร์กสเตชัน",
"addZone": "กำหนดโซน",
"addAccountWin": {
"titleAddAcc": "เพิ่มบัญชี",
"titleEditAcc": "แก้ไขบัญชี",
"cardNumber": "หมายเลขบัตรเข้าใช้งาน (ไม่บังคับ)",
"fName": "ชื่อ",
"lName": "นามสกุล"
},
"deleteUserTitle": "ลบบัญชี",
"deleteUserDesc": "คุณแน่ใจหรือไม่ว่าต้องการลบบัญชีนี้",
"deleteDeptTitle": "ลบแผนก",
"deleteDeptDesc": "คุณแน่ใจหรือไม่ว่าต้องการลบแผนกนี้",
"downloadTmpl": "คุณสามารถดาวน์โหลดเทมเพลตได้",
"here": "ที่นี่",
"inviteAllWin": {
"title": "เชิญบัญชี",
"desc": "คุณเลือก ${count} บัญชี คุณแน่ใจหรือไม่ว่าต้องการส่งอีเมลคำเชิญถึงบุคคลเหล่านี้"
},
"deleteZoneTitle": "",
"deleteZoneDesc": ""
},
"scene": {
"title": "ฉาก",
"noSceneDesc": "คุณยังไม่ได้สร้างฉากใดๆ คลิกปุ่มด้านล่างเพื่อสร้างฉากแรกของคุณ",
"win": {
"titleCreate": "สร้างฉาก",
"titleEdit": "แก้ไขฉาก",
"name": "ชื่อ",
"floor": "พื้น"
},
"addDeviceToScene": "คลิกอุปกรณ์เพื่อเพิ่มลงในฉาก",
"numOfDeviceInScene": "มีอุปกรณ์ ${count} เครื่องในฉากนี้",
"ctrlLogic": "ตรรกะการควบคุมเซ็นเซอร์แสง",
"illuminance": "ความสว่าง"
},
"heatmap": {
"title": "แผนที่ความร้อน",
"coolerRequest": "คำขอคูลเลอร์:",
"warmerRequest": "คำขอที่อบอุ่น:",
"noRecord": "ไม่พบบันทึก",
"action": {
"cooler": "คูลเลอร์",
"warmer": "อุ่นขึ้น"
},
"actionBy": "ผู้ขอ:",
"corridor": "ทางเดิน",
"groupCovered": "กลุ่มที่ครอบคลุม:",
"record": "บันทึก:",
"noAirconDesc": "ชั้นนี้ไม่มีเครื่องปรับอากาศแบบปรับอุณหภูมิได้ โปรดดูบันทึกกิจกรรมสำหรับกิจกรรมเปิด/ปิด",
"requestCount": "จำนวนคำขอ",
"defaultTemperature": "อุณหภูมิเริ่มต้น:",
"temperatureRange": "ช่วงอุณหภูมิ:",
"averageTemperature": "อุณหภูมิเฉลี่ย:",
"chartTitle": "บันทึกอุณหภูมิ",
"setPointChange": "ปรับอุณหภูมิเริ่มต้น"
},
"mainPage": {
"title": "ควบคุม"
},
"locationPage": {
"title": "ที่ตั้ง"
},
"scenePage": {
"title": "ฉาก"
},
"companyPage": {
"title": "การตั้งค่า"
},
"accountPage": {
"title": "บัญชี"
},
"heatmapPage": {
"title": "แผนที่ความร้อน"
},
"logPage": {
"title": "บันทึกกิจกรรม"
},
"dashboardPage": {
"title": "แดชบอร์ด"
},
"insightsPage": {
"title": "ข้อมูลเชิงลึก"
},
"schedulePage": {
"title": "กำหนดการ"
},
"calendarPage": {
"title": "ปฏิทิน"
},
},

  });

})();